body.page-template-cart{
    
    .main-content{
        padding: 60px 0 0 0;
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
        
        h1{
            text-align: center;
            margin: 40px 0 30px;
            
            @include tablet{
                margin: 50px 0 40px;
            }
        }
        
    }
    
}


