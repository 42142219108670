header.main {
    position:absolute;
    width:100%;
    height:50px;
    padding:0;
    padding-left:10px;
    z-index:100;
    
    background:rgba($primary_color, 0.9);
    &:after{
        position:absolute;
        content:"";
        bottom:-10px;
        left: 0%;
        height:10px;
        width: 100%;
        background:rgba($primary_color, 0.7);
    }

	.text_option {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		color: $light_color;
		width: 100%;
		padding: 0 35% 0 285px;
		display: none;
		@media (min-width: 1100px){
			display: block;
		}
		p {
			margin: 0;
		}
	}	
    
    .button-menu{
        position:relative;
        max-width:100px;
        z-index:60;
        margin-top: 10px;
        padding:7px 15px;
        
        color:$light_color;
        background: none;
        border:1px solid $light_color;
        text-transform: uppercase;
        font-size:12px;
        transition:all 0.3s;
        &:hover, &:focus{
            background:$dark_color;
        }
    }
    .contain-menu{
        height: 100%;
        overflow-y: scroll;
    }
    .nav-main-menu{
        display: none;
        position:absolute;
        top:0;
        left:0;
        width:100vw;
        height:100vh;
        padding-top:80px;
        z-index:50;
        background: $primary_color;
        text-transform: uppercase;
        ul, li{
            list-style:none;
        }
        a{
            text-decoration: none;
        }
        
        #main-menu{
            margin-bottom:30px;
            padding:0;
            text-align:center;
            li{
                margin-bottom:10px;
                a{
                    color:$text_base_color;
                    &:hover{
                        font-weight:bold;
                    }
                }
            }
        }
        
        //*****************LANG PART***********************/
        &__lang{
            max-width: 300px;
            margin: 0 auto 40px auto;
            ul{
                list-style:none;
                display:flex;
                padding:0 15px;

                text-align:center;
                li{
                    list-style:none;
                    width:calc(100% / 3);

                    border:1px solid $light_color;
                    transition:all 0.3s;
                    a{
                        display:block;
                        padding:5px 0;

                        color:$light_color;
                        transition:all 0.3s;
                    }
                    &:hover, &.active{
                        background: $dark_color;
                        a{
                            color:$light_color;
                        }
                    }
                    &:nth-child(2){
                        border-left:none;
                        border-right:none;
                    }
                }
            }
        }
        
        //*****************FOOTER MAIN MENU***********************/
        &__footer{
            position:relative;
            padding:25px 15px 15px 15px;

            color:$light_color;
            background: $dark_color;
            text-align: center;
            border-top:10px solid $border_color;
            &:before{
                position:absolute;
                content:"";
                top:0;
                left:0;
                height:1px;
                width:100%;

                background:$second_border_color;
            }
            a{
                color:$light_color;
            }
            &__contactBtn{
                display:inline-block;
                padding:10px 15px;
                margin-bottom: 10px;

                border: 1px solid $primary_color;
                text-transform: uppercase;
                transition:all 0.3s;
                &:hover, &:focus{
                    color:$dark_color;
                }
                
                position:relative;
                overflow:hidden;
                .innerText{
                    position:relative;
                    z-index:2;
                }
                
                &:before{
                    opacity: .5;
                }
                &:before, &:after{
                    position: absolute;
                    content: "";
                    display: block;
                    margin: -1px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;

                    background: $light_color;
                    transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                    transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                    transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                    transform-origin: top;
                    transform: scaleY(0);
                }

                &:hover, &:focus{
                    &:before{
                        transform: scaleY(1);
                        transition-duration: 80ms;
                    }
                    &:after{
                        transform: scaleY(1);
                        transition-duration: 250ms;
                    }
                }
            }
            &__phoneNumber a{
                transition:all 0.3s;
                &:hover, &:focus{
                    color:$primary_color;
                }
            }
            &__infos{
                text-transform:none;
                font-size:10px;
            }
        }
    }
    
    .logo{
        position:relative;
        z-index:60;
        margin-top:10px;
        padding:0 5px;
        picture{
            display: block;
            text-align:center;
            img{
                width:70px;
            }
        }
    }
    
    .market-menu{
        position:relative;
        z-index:60;
        padding:0;
        margin-top:10px;
        ul{
            padding:0;
            list-style: none;
            overflow: hidden;
            li{
                float:left;
                a{
                    display: block;
                    color:$light_color;
                    text-decoration:none;
                    width:28px;
                    height:28px;
                    @extend .font-light;
                    transition:all 0.3s;
                    &.my_account{
                        text-indent: -9999pt;
                        background: url(../images/my_account.svg) center center no-repeat;
                    }
                    &.my_cart{
                        text-indent: -9999pt;
                        background: url(../images/checkout.svg) center center no-repeat;
                    }
                    &:hover, &:focus{
                        transform: translateX(2px);
                    }
                }
                &:first-child{
                    margin-right: 15px;
                }
            }
        }
    }
    
    @media (min-width: 480px){
        .market-menu ul{
            padding-left:30px;
        }
    }
    
    @media (min-width: 600px){
        .market-menu ul{
            padding-left:55px;
        }
    }
    
    @media (min-width: 768px){
        .market-menu ul{
            padding-left:85px;
        }
    }
    
    @media (min-width: 992px){
        .market-menu ul{
            padding-left:125px;
        }
    }
    
    @media (min-width: 1100px) {
        &:after{
            width:100%;
        }
        .market-menu{
            margin-left:70%;
        }
        
        div.cols{
            padding:0;
        }

        button{
            display:none;
        }
        
        .logo{
            display:none;
        }
        
        .market-menu{
            padding:0;
            margin-top:15px;
            ul{
                padding:0;
                list-style: none;
                overflow: hidden;
                li{
                    float:left;
                    a{
                        color:$light_color;
                        text-decoration:none;
                        width:auto;
                        height:auto;
                        &.my_account{
                            text-indent: inherit;
                            padding-left:32px;
                            background: url(../images/my_account.svg) top left no-repeat;
                            @include specific-ie{
                                padding-left:115px;
                                position:relative;
                                right:-60px;
                            }
                        }
                        &.my_cart{
                            text-indent: inherit;
                            padding-left:32px;
                            background: url(../images/checkout.svg) top left no-repeat;
                            @include specific-ie{
                                padding-left:115px;
                            }
                        }
                    }
                    &:first-child{
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    
    @media (min-width: 1300px) {
        .market-menu{
            margin-left:80%;
            width:auto;
            @include specific-ie{
                margin-left:75%;
            }
        }
    }
}