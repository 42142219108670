@import url('https://fonts.googleapis.com/css?family=Lato:300,300i,400,700');

.font
{
	&-light
	{
		font-weight: 300;
	}

	&-regular
	{
		font-weight: 400;
	}

	&-semibold
	{
		font-weight: 600;
	}

	&-bold
	{
		font-weight: 700;
	}

	&-extrabold
	{
		font-weight: 800;
	}

	&-lato
	{
		font-family: 'Lato', 'Helvetica', sans-serif;
	}
}