.button_wrapper {
	text-align: center;
	@include desktop {
		text-align: left;
	}
}
.button-group{
    padding-top: 15px;
    text-align: center;
}
.button {
    vertical-align: top;
	@include desktop {
		width: auto;
	}
	&:after {
		
	}
	&:hover {
		
	}
    
    &--destination{
        border-radius: 0;
        box-shadow: none;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        padding: 12px 40px;
        margin: 15px 0;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        transition: all 0.2s ease;
        outline: 0;
        text-decoration: none;
        display: inline-block;
        
        @media(min-width: 600px){
            padding: 15px 70px;
        }
        
        &:hover, &:focus{
            background-color: #fff;
            color: $primary-color;
            text-decoration: none;
        }
        
    }

    &--shop{
        border-radius: 0;
        box-shadow: none;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        padding: 12px 40px;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        transition: all 0.2s ease;
        outline: 0;
        text-decoration: none;
        display: inline-block;
        
        @media(min-width: 600px){
            padding: 15px 70px;
        }
        
        &:hover, &:focus{
            background-color: #fff;
            color: $primary-color;
            text-decoration: none;
        }
        
    }
    
    &--smallShop{
        border-radius: 0;
        box-shadow: none;
        border: 1px solid $primary-color;
        background-color: $primary-color;
        padding: 6px 10px;
        color: #fff;
        font-size: 15px;
        transition: all 0.2s ease;
        outline: 0;
        text-decoration: none;
        display: inline-block;
        
        @media(min-width: 600px){
            padding: 8px 14px;
        }
        
        &:hover, &:focus{
            background-color: #fff;
            color: $primary-color;
            text-decoration: none;
        }
        
    }
    
    &--edit{
        display: inline-block;
        overflow: hidden;
        white-space: nowrap;
        text-indent: 200%;
        width: 25px;
        height: 25px;
        background: transparent url('../images/edit.svg') center center no-repeat;
        background-size: contain;
        transition: all 0.3s ease;
        border: 0;
        box-shadow: none;
        outline: none;
        
        &:hover, &:focus{
            transform: rotate(-20deg);
        }
        
    }
    
    &--delete{
        overflow: hidden;
        white-space: nowrap;
        text-indent: 200%;
        border-radius: 100%;
        border: 1px solid $danger-color;
        width: 30px;
        height: 30px;
        background: #fff;
        outline: 0;
        display: inline-block;
        position: relative;
        transform: rotate(45deg);
        transition: all 0.3s ease;
        text-decoration: none;
        
        &:after, &:before{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            background-color: $danger-color;
            transform: translate(-50%,-50%);
            transition: all 0.3s ease;
        }
        
        &:after{
            width: 2px;
            height: 70%;
        }
        
        &:before{
            width: 70%;
            height: 2px;
        }
        
        &:hover, &:focus{
            background-color: $danger-color;
            
            &:after, &:before{
                background-color: #fff;
            }
            
        }
        
    }
    
    &--tooltip{
        overflow: hidden;
        white-space: nowrap;
        text-indent: 200%;
        border-radius: 100%;
        border: 1px solid $dark_color;
        width: 20px;
        height: 20px;
        background: #fff url('../images/info.svg') center center no-repeat;
        background-size: 12px;
        outline: 0;
        display: inline-block;
        position: relative;
        transition: all 0.2s ease;
        text-decoration: none;
        padding: 0;
        
        &:hover, &:focus{
            border-color: $primary_color;            
        }
        
    }
    
}