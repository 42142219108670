.panel{
    
    border-radius: 0;
    border-color: $dark_color;
    
    &-heading{
        background-color: $dark_color !important;
        color: #fff !important;
        @extend .font-bold;
        border-radius: 0;
    }
    
    &-body{
        padding: 20px;
    }
    
}