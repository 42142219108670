body.single-product {
    background: $light_color;
    .main-content {
        position: relative;
        padding: 0;
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin: 0;
            margin-left: 250px;
        }

        div{
            padding: 0;
        }

        h2 {
            @extend .font-bold;
            padding-bottom: 30px;
        }

        h3 {
            @extend .font-bold;
            font-size: 16px;
            padding-bottom: 25px;
        }

        a,
        a:hover, 
        a:active, 
        a:focus,
        input {
            color: $light_color;
            text-decoration: none;
            outline: none;
            border: none;
        }

        ul {
            li {
                display: inline-block;
                list-style: none;
            }
        }

        button,
        button:hover, 
        button:active, 
        button:focus  {
            background: none;
            outline: none;
            border: none;
        }
        

        .header {
            width: 100%;
            height: 400px;
            position: relative;
            text-align: center;
            overflow: hidden;
            background-color: $darkest_color;
            img {
                position: absolute;
                width: 100%;
                object-fit: cover;
                height: 100%;
                @include laptop {
                    object-fit: initial;
                    height: auto;
                }
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                opacity: .75;
                /*
                @include mobile {
                    height: 100%;
                    width: auto;
                    top: 0;
                    transform: translate(-50%, 0);
                }
                */
            }

            .title,
            .subtitle {
                width: 100%;
                color: $light_color;
                position: absolute;
                // text-shadow: 1px 1px $text_base_color;
            }
            h1.title {
                @extend .font-regular;
                font-size: 35px;
                bottom: 170px;
            }
            .subtitle {
                @extend .font-light;
                font-size: 16px;
                line-height: 1;
                bottom: 125px;  
            }

            .buy {
                display: flex;
                min-height: 50px;
                height: 50px;
                background-color: $primary_color;    
                transition: right .3s;
                z-index: 20;

                position: fixed;
                width: 100%;
                top: initial;
                right: initial;
                bottom: 0;
                margin-left: 0;

                @include laptop {
                    position: absolute;
                    width: initial;
                    margin-left: 20px;
                    right: 20px;
                    bottom: 20px;
                }

                &.docked {
                    position: fixed;
                    top: initial;
                    right: 0;
                    bottom: 0;

                    @include laptop {
                        top: 0;
                        bottom: initial;
                    }
                }

                .price_from {
                    background-color: $light_color;
                    width: 50%;
                    padding: 5px 20px;
                    padding: 3px 20px 0;
                    margin: 1px;
                    color: $primary_color;
                    .from {
                        @extend .font-light;
                        font-size: 12px;
                    }
                    .price {
                        @extend .font-bold;
                        font-size: 18px;
                    }
                }
                a.buy-button {
                    @extend .font-regular;
                    width: 100%;
                    text-align: center;
                    font-size: 16px;
                    color: $light_color;
                    text-transform: uppercase;
                    // padding: 0 20px;
                    @include laptop {
                        padding: 0 40px;
                    }
                }
            }

        }

        .informations {
            width: 100%;
            ul {
                margin: 0;
                padding: 0;
                text-align: center;

                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                justify-content: space-around;
                align-items: flex-start;
                
            }

            .item {
                height: auto;
                @include tablet {
                    height: 95px;
                }
                padding: 0 18px;

                // width: 100%;
                // margin: 10px 0;

                // @include tablet {
                    width: 50%;
                    margin: 0;
                    &:nth-child(2n) {
                        border-left: 1px solid $dark_color;
                    }

                    &:nth-child(-n+2) {
                    margin-top: 10px;
                    }
                    &:nth-child(n+5) {
                        margin-bottom: 10px;
                    }
                // }
                
                @include laptop {
                    width: 33%;
                    margin: 0;
                    &:nth-child(2n+1) {
                        border-left: 1px solid $dark_color;
                    }
                    &:nth-child(3n+1) {
                        border-left: none;
                    }

                    &:nth-child(-n+3) {
                    margin-top: 10px;
                    }
                    &:nth-child(n+4) {
                        margin-bottom: 10px;
                    }
                }
                @include desktop {
                    // width: auto;
                    width: 15%;
                    &:nth-child(4n) {
                        border-left: 1px solid $dark_color;
                    }
                    &:nth-child(n) {
                        margin: 20px 0;
                    }
                    &.landscapes,
                    &.ambiances {
                        width: 20%;
                    }
                }

                h3 {
                    @extend .font-semibold;
                    color: $text_title_color;
                    font-size: 16px;
                    text-transform: uppercase;

                    margin-bottom: 10px;
                    @include desktop {
                        // margin-bottom: 35px;
                    }
                    
                    @media screen and (max-width: 380px){
                        word-break: break-all;
                    }
                    
                }
                // @extend .font-regular;
                color: $primary_color;

                &.landscapes,
                &.ambiances {
                    h3 {
                        margin-bottom: -5px;
                        @include desktop {
                            // margin-bottom: 15px;
                        }   
                    }

                    ul {
                        // display: flex;
                        // justify-content: space-around;
                        // align-items: center;
                        // flex-direction: column;
                        flex-wrap: wrap;
                        @include tablet {
                            flex-wrap: nowrap;
                        }
                    }
                }

                .landscape,
                .ambiance {
                    color: $text_title_color;
                    .content {
                        margin: 5px 5px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        .icon {
                            img {
                                width: 30px;
                                height: 30px;
                            }
                            margin-bottom: 4px;
                        }
                        .label {
                            color: $text_title_color;
                        }
                    }
                }
            }
        
        }

        .menu {
            width: 100%;
            min-height: 50px;
            background-color: $dark_color;
            border-bottom: 8px solid $border_color;
            z-index: 10;

            position: relative;
            top: initial;

            .items {
                width: 100%;
                @include laptop {
                    width: calc(100% - 350px);
                }
                height: 100%;
                .item {
                    margin: 12px 15px 0 0;
                    @include laptop {
                        margin: 8px 50px 0 0;
                    }
                    a {
                        @extend .font-light;
                        color: $light_color;
                        &.active {
                            @extend .font-bold;
                        }
                    }
                }
            }

            &.docked {
                position: fixed;
                top: 0;
                .items {
                    width: 100%;
                    @include laptop {
                        width: calc(100% - 350px);
                    }
                }
            }
        }

        .description {
            width: 100%;
            height: auto;
            padding: 65px 20px 10px;
            @include laptop {
                padding: 65px 80px 0;
            }
            color: $darkest_color;
            display: flex;
            flex-direction: column;
            @include laptop {
                flex-direction: row;
            }

            .description-column {
                width: 100%;
                padding-right: 0;
                @include laptop {
                    padding-right: 20px;
                    width: 45%;
                    &.full-width {
                        padding-right: 0;
                        width: 100%;
                    }
                }

                .text,
                .label {
                    padding-bottom: 25px;
                    @extend .font-light;
                    font-size: 16px;
                    color: $darkest_color;
                }

                .tools {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    justify-content: space-around;

                    .tool {
                        display: flex;
                        flex-direction: column; 
                        align-items: center;
                        text-align: center;
                    }
                    .icon {
                        width: 50px;
                        height: 50px;
                        margin: 10px;
                    }
                    .share {
                        cursor:pointer;
                        .icon {
                            background: url(../images/icon_description_share_on.svg) no-repeat;
                        }
                    }
                    .print {
                        .icon {
                            background: url(../images/icon_description_print_on.svg) no-repeat;
                        }
                    }
                    .download {
                        .icon {
                            background: url(../images/icon_description_download_on.svg) no-repeat;
                        }
                    }
                }
                .shareLinks{
                    display:none;
                    padding: 5px 15px;
                    margin-bottom: 20px;
                    /*border: 1px solid $primary_color;*/
                    div{
                        display:flex;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                    }
                    &__item{
                        width:100px;
                        .icon {
                        width: 50px;
                            height: 50px;
                            margin: 10px;
                        }
                        &.email{
                            .icon {
                                background: url(../images/share_mail.svg) no-repeat;
                            }
                        }
                        &.facebook{
                            .icon {
                                background: url(../images/share_facebook.svg) no-repeat;
                            }
                        }
                    }
                    a{
                        display: inline-block;
                        color:$text_base_color;
                        @extend .font-light;
                    }
                }
            }

            .maps-column {
                width: 100%;
                @include laptop {
                    width: 55%;
                }
                font-size: 0;

                .maps {
                    position: relative;
                    width: 100%;
                    .map {
                        img {
                            position: absolute;
                            width: 100%;
                            z-index: 2;
                            bottom: 0;
                            right: 0;
                            transform: scale(.25);
                            transform-origin: bottom right;
                        }
                        &.active {
                            img {
                                position: relative;
                                z-index: 1;
                                box-shadow: 10px 10px 0px 0px rgba($dark_color, 0.15);
                                transform: scale(1);
                                // transition: transform 1s;
                            }
                        }
                    }
                }
            }
        }
        .gallery {
            width: 100%;
            padding: 50px 20px 10px;
            @include laptop {
                padding: 50px 65px 10px;
            }
            // display: flex;
            // // justify-content: space-between;
            // flex-wrap: wrap;
            // align-items: center;
            // flex-direction: column;
            // @include laptop {
            //     flex-direction: row;
            // }

            text-align: center;
            position: relative;
            .owl-nav {
                display: block;
                position: absolute;
                height: 300px;
                width: auto;
                right: 45px;
                top: 35%;
                @include tablet-max {
                    display: block;
                    position: absolute;
                    top: 91%;
                    width: 100vw;
                    right: 0;
                }
                @include mobile {
                    display: block;
                    position: absolute;
                    top: 81%;
                    width: 100vw;
                    right: 0;
                }
                .owl-prev {
                    display: none;
                    //position: absolute;
                    @include tablet-max {
                        display: block;
                        position: absolute;
                        left: 28px;
                    }
                    span {
                        font-size: 70px;
                        @include mobile {
                            font-size:65px;
                        }
                    }
                 }
                 .owl-next {
                     display: block;
                     position: absolute;
                     @include tablet-max {
                         right: 22px;
                     }
                     span {
                         font-size: 70px;
                         @include mobile {
                            font-size: 65px;
                        }
                     }
                 }
             }
            .image,
            .video {
                padding: 20px 0;
                margin: 0;
                position: relative;
                padding: 15px;
                // padding: 15px 0;
                // width: 100%;
                // @include laptop {
                //     padding: 15px 15px;
                //     width: 33%;
                // }
                // @include desktop {
                //     width: 25%;
                // }
                // padding: 10px;
                
                a{
                    display: block;
                    width: 100%;
                    padding-bottom: 70%;
                    position: relative;
                    overflow: hidden;
                    box-shadow: 10px 10px 0px 0px rgba($dark_color, 0.15);
                }

                &__background, img {
                    position: absolute;
                    min-width: 100%;
                    min-height: 100%;
                    max-width: 140%;
                    width: auto;
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    // en background cover
                    //padding-bottom: 70%;
//                    background-size: cover;
//                    background-position: center center;
                    
                }
            }

            /* 
            .image,
            .video {
                padding: 20px 0;
                margin: 0;
                position: relative;
                width: 100%;
                @include laptop {
                    padding: 20px 10px;
                    width: 33%;
                }
                @include desktop {
                    width: 25%;
                }

                img {
                    margin: 0;
                    box-shadow: 10px 10px 0px 0px rgba($dark_color, 0.15);
                    max-width: 100%;
                    @include laptop {
                        // maquette 215x160
                        max-height: 160px;
                    }
                }
            }
            */
            
            .video .play {
                position: absolute;
                background: url('../images/icon_video_play.svg');
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 50px;
                height: 50px;
            }
        }

        .characteristics {
            width: 100%;
            padding: 50px 20px;
            @include laptop {
                padding: 50px 80px;
            }
            .characteristic {
                position: relative;
                margin: 25px 0;
                //arrow
                &:before {
                    content: '';
                    position: absolute;
                    top: 5px;
                    right: 25px;
                    border: solid $darkest_color;
                    border-width: 0 2px 2px 0;
                    padding: 5px;
                    transition: transform .3s; 
                    // transform: rotate(135deg) translate(0, -5px); //left
                    transform: rotate(-45deg) translate(-5px, 0px); //right

                }
                //break-line
                border-bottom: 1px solid $darkest_color;

                button.collapse-button {
                    position: absolute;
                    width: 100%;
                    height: 30px;
                    top: 0;
                    left: 0;
                }

                .content {
                    overflow: hidden;
                    transition: height 0s; //transition-duration set in single-product.js
                }

                &.open {

                    //arrow
                    &:before {
                        content: '';
                        // transform: rotate(-135deg) translate(-5px, -5px); //up
                        transform: rotate(45deg);  //down
                    }

                    .content {
                        margin-bottom: 15px;
                    }
                    
                }

            }

            
        }

        .comments {
            width: 100%;
            position: relative;
            // background-color: $dark_color;
            background: $dark_color url(../images/comments_background.jpg) right bottom no-repeat;
            background-size: 100% auto;
            // background-size: 30px;
            padding: 50px 20px;
            @include laptop {
                padding: 50px 80px;
            }

            h2 {
                color: $light_color;
                display: inline-block;
            }

            .average-rating {
                display: inline-block;
                padding: 0 20px;
                .star {
                    display: inline-block;
                    position: relative;
                    width: 14px;
                    height: 14px;
                    margin: 0 5px 2px;
                    &__fill {
                        position: absolute;
                        width: inherit;
                        height: inherit;
                        background: url(../images/star_white.png);
                    }
                    &__background {
                        position: absolute;
                        width: inherit;
                        height: inherit;
                        background: url(../images/star_white.png);
                        opacity: .5;
                    }
                }
            }

            &__list {
                position: relative;
                .no-comment {
                    color: $light_color;
                    font-size: 16px;
                    text-transform: uppercase;
                }

                .pagination {
                    width: 100%;
                    padding: 10px 0;
                    margin: 0;
                    text-align: center;
                    font-size: 0;
                    .page-numbers,
                    .current {
                        color: $light_color;
                        font-size: 16px;
                        border: 1px solid $primary_color;
                        padding: 10px 20px;
                        cursor: pointer;
                        user-select: none;
                        border-left: none;
                        &:first-child {
                            border-left: 1px solid $primary_color;
                        }
                        transition: all .3s;
                        &:hover, 
                        &:focus {
                            color: $light_color;
                            background: $primary_color;
                        }
                    }
                    .current {
                        background:  $primary_color;
                    }
                }

                .comment {
                    position: relative;
                    padding: 20px;
                    margin: 0 0 30px;
                    background-color: $light_color;
                    box-shadow: 10px 10px 0px 0px $border_color;
                    min-height: 140px;
                    
                    .author {
                        color: $primary_color;
                        display: inline-block;
                    }

                    .rating {
                        display: inline-block;
                        padding: 0 10px;
                        .star {
                            display: inline-block;
                            position: relative;
                            width: 12px;
                            height: 12px;
                            margin: 0 5px;
                            &__fill {
                                position: absolute;
                                width: inherit;
                                height: inherit;
                                background: url(../images/star.png) ;
                                background-size: 12px 12px;
                            }
                            &__background {
                                position: absolute;
                                width: inherit;
                                height: inherit;
                                background: url(../images/star.png);
                                background-size: 12px 12px;
                                opacity: .5;
                            }
                        }
                    }


                    .content {
                        overflow: hidden;
                        white-space: pre-wrap;
                        transition: height .3s;

                        .full,
                        .truncated {
                            margin-right: 40px;
                        }
                        .truncated {
                            display: none;
                        }
                    }

                    .more {
                        &.hide {
                            display: none;
                        }
                        
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        right: 15px;
                        bottom: 10px;
                        cursor: pointer;
                        &:before {
                            content: '';
                            position: absolute;
                            width: 30px;
                            height: 3px;
                            background-color: $primary_color;
                            top: 14px;
                            left: 0;
                        }
                        &:after {
                            content: '';
                            position: absolute;
                            width: 3px;
                            height: 30px;
                            background-color: $primary_color;
                            top: 0;
                            left: 14px;
                        }
                    }
                }

                .add-comment {
                    position: absolute;
                    right: 0;
                    bottom: -30px;
                    @include laptop {
                        bottom: -5px;
                    }
                    padding-right: 40px;
                    cursor: pointer;
                    @extend .font-bold;
                    color: $light_color;
                    font-size: 16px;
                    text-transform: uppercase;
                    user-select: none;
                    //arrow
                    &:before {
                        content: '';
                        position: absolute;
                        top: 3px;
                        right: 5px;
                        border: solid $light_color;
                        border-width: 0 2px 2px 0;
                        padding: 5px;
                        transition: transform .3s; 
                        transform: rotate(135deg) translate(0, -5px); //left
                    }

                    &.open {
                        //arrow
                        &:before {
                            content: '';
                            // transform: rotate(-135deg) translate(-5px, -5px); //up
                            // transform: rotate(-45deg) translate(-5px, 0px); //right
                            transform: rotate(45deg);  //down
                        }
                    }
                }
            }

            .comments-form {
                margin-top: 40px;
                @include laptop {
                    margin-top: 20px;
                }
                position: relative;
                overflow: hidden;
                // @extend .font-bold;
                color: $light_color;
                font-size: 16px;
                text-transform: uppercase;
                transition: all .3s;

                .acf-required {
                    color: $light_color;
                }

                label {
                    @extend .font-regular;
                }

                textarea,
                input[type="text"] {
                    width: 100%;
                    // background: none;
                    outline: none;
                    border: none;
                    color: $dark_color;
                }

                .form-submit {
                    text-align: center;
                    input.submit {
                        width: 50%;
                        height: 50px;
                        border: 1px solid $primary_color !important;
                        background: none;
                        padding-top: 0;
                        text-transform: uppercase;
                        transition: all 0.3s;
                        &:hover, 
                        &:focus {
                            color: $light_color;
                            background: $primary_color;
                        }
                    }
                }

                &__error {
                    border: 1px solid red !important;
                }

                .ratings {
                    padding: 0 10px;
                    // unicode-bidi: bidi-override;
                    // direction: rtl;
                    .rating {
                        margin: 0 5px;
                    }
                }

                // // TODO : https://codepen.io/lsirivong/pen/ekBxI
                //
                // .ratings {
                //     margin: 0;
                //     padding: 0 10px;
                //     // remove inline-block whitespace
                //     font-size: 0;
                //     * {
                //         font-size: 1rem;
                //     }
                //     // flip the order so we can use the + and ~ combinators
                //     unicode-bidi: bidi-override;
                //     direction: rtl;
                //     & > input {
                //         display: none;

                //         & + label {
                //                 // only enough room for the star
                //                 display: inline-block;
                //                 overflow: hidden;
                //                 text-indent: 9999px;
                //                 width: 1em;
                //                 white-space: nowrap;
                //                 cursor: pointer;
                //                 margin: 0;
                //                 padding: 0;
                //             &:before {

                //                 display: inline-block;
                //                 text-indent: -9999px;
                //                 content: url(../images/star_white.png);
                //                 opacity: .5;
                //             }
                //         }

                //         &:checked ~ label:before,
                //         & + label:hover ~ label:before,
                //         & + label:hover:before {
                //             content: url(../images/star_white.png);
                //             opacity: 1;
                //         }
                //     }

                //     // the hidden clearer
                //     & > .star-cb-clear + label {
                //         text-indent: -9999px;
                //         width: .5em;
                //         margin-left: -.5em;
                //     }

                //     & > .star-cb-clear + label:before {
                //         width: .5em;
                //     }

                //     &:hover > input + label:before {
                //         content: url(../images/star_white.png);
                //         opacity: .5;
                //     }
                //     &:hover > input + label:hover ~ label:before,
                //     &:hover > input + label:hover:before {
                //         content: url(../images/star_white.png);
                //         opacity: 1;
                //     }

                // }


                
            }



        }

        .others-destinations {
            width: 100%;
            padding: 50px 20px;
            @include laptop {
                padding: 50px 80px;
            }
            .links {
                display: flex;
                flex-direction: column;
                @include laptop {
                    flex-direction: row;
                }
                .link {
                    
                    width: 100%;
                    text-align: center;
                    margin: 0;
                    @include laptop {
                        &:nth-child(1) {
                            margin-right: 14px;
                        }
                        &:nth-child(2) {
                            margin: 0 7px;
                        }
                        &:nth-child(3) {
                            margin-left: 14px;
                        }
                        width: 33%;
                    }
                    
                    &__image {
                        width: 100%;

                        // en background cover
                        padding-bottom: 70%;
                        background-size: cover;
                        background-position: center center;
                        box-shadow: 10px 10px 0px 0px rgba($dark_color, 0.15);

                        // en picture en largeur 100% + hauteur auto
                        // picture {
                        //     img {
                        //         width: 100%;
                        //         box-shadow: 10px 10px 0px 0px rgba($dark_color, 0.15);

                        //         // ou avec "cover" en hauteur fixe
                        //         // &.cover {
                        //         //     max-height: 245px;
                        //         //     @include laptop {
                        //         //         height: 245px;
                        //         //     }
                        //         //     object-fit: cover;
                        //         // }
                        //     }
                        // }
                    }
                    &__content {
                        color: $text_title_color;
                        font-size: 16px;
                        margin: 20px;
                    }

                    &__title {
                        @extend .font-bold;
                    }
                    &__detail {
                        @extend .font-light;
                        @include laptop {
                            min-height: 130px;
                        }
                    }
                    &__price__from {
                        @extend .font-light;
                        font-size: 12px;
                        margin-top: 20px;
                    }
                    &__price {
                        color: $primary_color;
                        @extend .font-bold;
                        font-size: 18px;
                    }
                    a {
                        @extend .font-regular;
                        color: $text_title_color;
                        text-transform: uppercase;
                        .url {
                            width: 100%;
                            height: 50px;
                            border: 1px solid $primary_color;
                            margin: 15px 0 60px;
                            padding-top: 12px;
                            transition: all 0.3s;
                            &:hover, 
                            &:focus {
                                color: $light_color;
                                background: $primary_color;
                            }
                            
                        }
                    }
                }
            }
        }
    }
}
