html, body {
	@extend .font-lato, .font-regular;
	font-size: $font_mobile_base;
    
    @include tablet{
        font-size: $font_base;
    }
    
}

h1, h2, h3{
	margin: 0;
	padding: 0;
    text-transform: uppercase;
}

h1 {
	font-size: $font_mobile_big_title;
    letter-spacing: 100/1000 * $font_mobile_big_title;
    
    @include desktop {
        font-size: $font_big_title;
        letter-spacing: 100/1000 * $font_big_title;
	}
}

h2{
	font-size: $font_mobile_title;
    letter-spacing: 100/1000 * $font_mobile_title;

    @include desktop {
        font-size: $font_title;
        letter-spacing: 100/1000 * $font_title;
    }
}

h3{
    font-size: $font_mobile_small_title;
    letter-spacing: 100/1000 * $font_mobile_small_title;
    
    @include desktop {
        font-size: $font_small_title;
        letter-spacing: 100/1000 * $font_small_title;
	}
}