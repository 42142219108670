body.blog {
	background: $light_color;
    
    .main-content{
        padding:0;
        div{
            padding:0;
        }
        
        //======================HEAD PAGE======================/
        .contain-head-listpage{
            position:relative;
            overflow: hidden;
            &__text{
                position:absolute;
                // top:35%;
                top:50%;
                left:50%;
                transform:translateX(-50%);
                width:85%;
                z-index:1;
                &__title{
                    margin-bottom: 15px;
                    
                    color:$light_color;
                    line-height: 40px;
                    text-align: center;
                }
            }
            
            &__contain-image{
                // max-height:320px;
                max-height:520px;
                picture{
                    display:block;
                    // max-height:320px;
                    max-height:520px;
                    overflow:hidden;
                    img{
                        width:100%;
                    }
                }
            }
        }
        
        .contain-list-articles{
            padding:35px 30px 45px 30px;
            &__item{
                display: block;
                position: relative;
                padding-bottom:30px;
                margin-bottom: 25px;
                
                color:$text_base_color;
                text-decoration:none;
                border-bottom: 1px solid $primary_color;
                article{
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: left;
                }
                &__thumbnail{
                    width:200px;
                    height:auto;
                    margin-right: 15px;
                    display:none;
                    @include tablet{
                        display:block;
                    }
                    img{
                        max-width: 100%;
                        height:auto;
                    }
                }
                &__container{
                    width:100%;
                    @include tablet{
                        width:calc(100% - 215px);
                    }
                }
                &__title{
                    margin-bottom: 15px;
                }
                &__date{
                    margin-bottom: 5px;
                }
                &__content{
                    @extend .font-light;
                }
                &:last-child{
                    border-bottom:none;
                }
                .plusarticle{
                    position:absolute;
                    bottom:10px;
                    right:15px;
                    width:20px;
                    height:20px;
                    background:url(../images/plus_article.svg) no-repeat;
                    background-size:20px;
                    transition:all 0.3s;
                }
                &:hover,&:focus{
                    .plusarticle{
                        transform: rotate(180deg);
                    }
                }
            }
            .navigation{
                ol, li{
                    list-style: none;
                }
                ol{
                    display:flex;
                    justify-content: center;
                    padding:0;
                    margin:0 auto;
                    li{
                        display: block;
                        a,span{
                            padding:5px 15px;
                            text-decoration:none;
                            color:$text_base_color;
                            border:1px solid $primary_color;
                            border-right:none;
                            @extend .font-light;
                            transition:all 0.3s;
                            &:hover, &:focus, &.current{
                                color:$light_color;
                                background: $primary_color;
                            }
                        }
                        &:last-child{
                            a,span{
                                border-right:1px solid $primary_color;
                            }
                        }
                    }
                }
            }
        }

        @media (min-width: 480px) {
        }
        
        @media (min-width: 600px){
        }
        
        @media (min-width: 768px) {
        }

        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
            
            //======================HEAD PAGE======================/
            .contain-head-listpage{
                
            }
        }

        @media (min-width: 1300px) {
            //======================HEAD PAGE======================/
            .contain-head-listpage{
            }
        }
    }
}
