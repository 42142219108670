body.page-template-page-my-account{
    
    .main-content{
        padding: 60px 0 0 0;
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
        
        h1{
            text-align: center;
            margin: 40px 0 30px;
            
            @include tablet{
                margin: 50px 0 40px;
            }
        }
        
    }
    
}

.myAccount{
    
    .accountDashboard{
        
        h2{
            text-transform: none;
            span{
                color: $primary_color;
            }
        }
        
        #logout-form{
            a{
                color: $danger-color;
            }
        }
        
        &__intro{
            margin-top: 25px;
            a{
                color: $primary-color;
            }
        }
        
        &__checkout, &__pendingOrder{
            margin-top: 25px;
            
            p{
                margin: 0;
            }
            
            .button{
                margin-top: 10px;
                text-transform: none;
                padding: 10px 20px;
        
                @media(min-width: 600px){
                    padding: 12px 24px;
                }
            }
        }
        
    }
    
    .detailOrder{
        
        .info-detail-order{
            margin-bottom: 25px;
            p{
                margin-bottom: 4px;
            }

        }
        
        .cart, .cart__total{
            padding: 0;
        }
        .cart__total{
            margin-top: 25px;
        }
        
    }
    
}


