.favTooltip{
    
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 500px;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border: 1px solid $primary_color;
    padding: 15px;
    width: 90%;
    max-width: 550px;
    z-index: 200;
    box-shadow: 5px 5px 0 0 rgba(93,90,84,.15);
    transition: all 0.2s ease;
    
    @media(min-width:600px){
        padding: 20px;
        border: 1px solid $primary-color;
        box-shadow: 10px 10px 0 0 rgba(93,90,84,.15);
    }
    
    &.active{
        
        opacity: 1;
        visibility: visible;
    }
    
    h3{
        text-align: center;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $primary_color;
    }
    
    .favTooltip__close{
        position: absolute !important;
        top: 0;
        right: 0;
        width: auto;
        height: 28px;
        padding: 3px 5px 5px 30px;
        border-radius: 0;
        border-top: 0;
        border-right: 0;
        border-color: $primary_color;
        transform: none;
        text-indent: 0;
        line-height: 1;
        color: $primary_color;
        
        &:after, &:before{
            background-color: $primary_color;
            transform: translate(-50%, -50%) rotate(45deg);
            transform-origin: center;
            left: 15px;
        }
        &:before{
            width: 20px;
        }
        &:after{
            height: 20px;
        }
        
        &:hover, &:focus{
            background-color: #fff;
            color: $danger-color;
            
            &:after, &:before{
                background-color: $danger-color;
            }
            
        }
        
    }
    
    > *:last-child{
        margin-bottom: 0;
    }
    
}