form:not(.shop-form) {
	label {

	}
	input, textarea, select {
		border: none;
		border-radius: 0;
		box-shadow: none;
	}
	
	textarea {
		
	}

	input[type=submit], button {
		
	}
}

.gform_wrapper{
    margin:0 !important;
    .gfield{
        padding:0 !important;
        label{
            font-size:$font_mobile_base !important;
            @include desktop {
                font-size:$font_base !important;
            }
        }
        label.material{
            margin-bottom: 0;
            color:$text_base_color;
            font-weight:300;
            // transform: translate(15px, 42px);
            // transition: transform ease 0.3s;
            // filter:blur(0);
            // &.focus{
            //     transform: translate(5px, 5px);
            // }
            // @include specific-ie{
            //     transform:none;
            //     transition: none;
            // }
        }
    }
    
    .ginput_container_select, .address_country{
        display: inline-block;
        border: 1px solid $primary_color;
        width: 100%;//240px;
        border-radius: 0px;
        overflow: hidden;
        background: $light_color url('../images/select_arrow_down.png') no-repeat 95% 50%;
        

        select {
            padding: 5px 15px 5px 10px !important;
            width: 105% !important;
            border: none !important;
            box-shadow: none !important;
            color:$color_dropdown_text;
            background: transparent !important;
            background-image: none !important;
            appearance: none !important;
            -webkit-appearance: none !important;
            -moz-appearance: none !important;
        }

        select:focus {
            outline: none !important;
        }
        @media (min-width: 480px){
            background: $light_color url('../images/select_arrow_down.png') no-repeat 98% 50%;
        }
    }
    
    .address_country{
        padding-right:0 !important;
        label{
            display:none !important;
        }
    }
    
    
    .ginput_container.ginput_container_checkbox{
        label{
            padding-left:0px !important;
            font-weight:300;
        }
        input[type=checkbox] {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
            }

            // Box.
            & + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: $light_color;
                border:1px solid $primary_color;
            }

            // Box hover
            &:hover + label:before {
                background: $primary_color;
            }

            // Box focus
            &:focus + label:before {
                box-shadow: 0 0 0 3px rgba($primary_color, 0.12);
            }

            // Box checked
            &:checked + label:before {
                background: $light_color;
            }

            // Disabled state label.
            &:disabled + label {
                color: rgba($primary_color, 0.20);
                cursor: auto;
            }

            // Disabled box.
            &:disabled + label:before {
                box-shadow: none;
                background: rgba($primary_color, 0.20);
            }

            // Checkmark. Could be replaced with an image
            &:checked + label:after {
                content: '';
                position: absolute;
                left: 7px;
                top: -1px;
                width: 9px;
                height: 20px;
                border: solid $primary_color;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }
    
    
    .ginput_container{
        label{
            padding-left:20px !important;
            @extend .font-light;
        }
        input[type=text]{
            width:100% !important;
            padding:15px 10px !important;
            height:50px;
            
            border:1px solid $primary_color !important;
        }
        textarea{
            border:1px solid $primary_color !important;
        }
    }
    
    .gform_footer{
        text-align:center;
        button{
            display:inline-block;
            min-width:250px;
            padding:15px 0;

            color:$primary_color;
            text-decoration: none;
            text-transform: uppercase;
            text-align:center;
            border:1px solid $primary_color;
            border-radius:0;
            background: none;
            transition: all 0.3s;
            &:hover, &:focus{
                color:$light_color;
                /*background: $primary_color;*/
            }
            
            
            position:relative;
            overflow:hidden;
            .innerText{
                position:relative;
                z-index:2;
            }
            
            &:before{
                opacity: .5;
            }
            &:before, &:after{
                position: absolute;
                content: "";
                display: block;
                margin: -1px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;

                background: $primary_color;
                transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                transform-origin: top;
                transform: scaleY(0);
            }

            &:hover, &:focus{
                &:before{
                    transform: scaleY(1);
                    transition-duration: 80ms;
                }
                &:after{
                    transform: scaleY(1);
                    transition-duration: 250ms;
                }
            }
            
            
            @media (min-width: 768px) {
                min-width:200px;
            }
        }
        .gform_ajax_spinner{
            display:none !important;
        }
    }
}

.social-news__newsletter__form{
    #gform_1{
        @media (min-width: 768px) {
            display:flex;
            .gform_body{
                width:60%;
                padding-right:15px;
            }
            .gform_footer{
                width:40%;
                margin-top:15px !important;
            }
        }
    }
    .gfield{
        padding:0 !important;
        margin-top:0 !important;
        label{
            font-weight:300;
        }
        label.material{
            opacity: 0;
            // display: none;
            // margin-bottom: 0;
            // color:$text_base_color;
            // font-weight:300;
            // transform: translate(15px, 42px);
            // transition: transform ease 0.3s;
            // filter:blur(0);
            // &.focus{
            //     color:$light_color;
            //     transform: translate(5px, 5px);
            // }
        }
    }
    .ginput_container{
        input[type=text]{
            width:100% !important;
            padding:15px 10px !important;
            height:50px;
            border: none !important;
        }
    }
    .gform_footer{
        margin-top: 0 !important;
        text-align: center;
        
    }
    #gform_submit_button_1{
        display:block;
        padding:15px 50px;
        height:50px;
        position:relative;
        left:50%;
        transform: translateX(-50%);

        border-radius:0;
        color:$light_color;
        font-size:16px;
        text-decoration: none;
        text-transform: uppercase;
        text-align:center;
        background: none;
        border:1px solid $primary_color;
        transition: all 0.3s;
        &:hover, &:focus{
            color:$light_color;
            background: $primary_color;
        }
        @media (min-width: 768px) {
            left:0%;
            transform: none;
        }
    }
}

.social-news__newsletter{
    
    form{
        
         .gform_footer{
             button{
                 color: #fff;
             }
        }
        
    }
    
}