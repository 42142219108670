.myAccount{
    padding: 0 15px;
    
    @include tablet{
        display: flex;
    }
    
    &__nav{
        
        margin-bottom: 30px;
        
        @include tablet{
            width: 200px;
        }
        
        ul{
            margin: 0 auto;
            padding: 0;
            list-style: none;
            display: flex;
            flex-flow: wrap;
            max-width: 500px;
            
            li{
                width: 100%;
                text-align: center;
                
                @media(min-width:500px){
                    width: 50%;
                }
                
                @include tablet{
                    width: 200px;
                    text-align: left;
                }
                
                a{
                    display: block;
                    width: 100%;
                    padding: 12px 20px;
                    border: 1px solid $primary-color;
                    border-bottom: 0 solid $primary-color;
                    color: $primary-color;
                    transition: all 0.2s ease;
                    
                    @media (min-width:500px) and (max-width:767px){
                        border-bottom: 1px solid $primary-color;
                    }
                    
                    &:hover, &:focus, &.active{
                        background-color: $primary-color;;
                        color: #fff;
                        text-decoration: none;
                    }
                    
                }
                &:last-child{
                    a{
                        border-bottom: 1px solid $primary-color;
                    }
                }
            }
            
        }
        
    }
    
    &__content{
        
        @include tablet{
            padding-left: 25px;
            width: calc(100% - 200px);
        }
        
    }
    
}