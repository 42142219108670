body.page-template-product-option{
    
    .main-content{
        padding: 60px 0 0 0;
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
        
        h1{
            text-align: center;
            margin: 40px 0 30px;
            
            @include tablet{
                margin: 50px 0 40px;
            }
        }
        
    }
    
}

.favshop_option{
    
    .productInfo{
        max-width: 320px;
        margin: 0 auto 60px;
        @extend .clearfix;
        
        @include tablet{
            max-width: 700px;
            margin: 0 auto 60px;
            box-shadow: 10px 10px 0 0 rgba(93,90,84,.15);
            display: flex;
            align-items: center;
        }

        &__img{
            width: 100%;
            @include tablet{
                width: 40%;
            }
            
            picture img{
                display: block;
                width: 100%;
                height: auto;
            }
            
        }
        
        &__content{
            width: 100%;
            padding: 20px;
            
            @include tablet{
                width: 60%;
                padding: 30px;
            }
            
            h2{
                font-size: 16px;
                margin: 0 0 8px;
                @extend .font-bold;
                text-align: center;
                
                @include tablet{
                    text-align: left;
                }
            }
            
            .total_line{
                text-align: center;
                
                @include tablet{
                    text-align: left;
                }
                
            }
            
        }

    }
    
    .total_line{
        font-size: 16px;
        color: $primary_color;
        @extend .font-bold;
    }
    
    .formStep{
        padding: 0 15px 40px;
        border-bottom: 1px solid $primary_color;
        margin-bottom: 40px;
        
        &:last-child{
            padding: 0 15px 0;
            border-bottom: 0;
        }
        
        &__title{
            font-size: 24px;
            @extend .font-bold;
            margin-bottom: 30px;
            position: relative;
            padding-left: 60px;
            
            &:before{
                content: "0";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 100%;
                border: 1px solid $primary_color;
                width: 44px;
                height: 44px;
                font-size: 24px;
                @extend .font-bold;
                text-align: center;
                line-height: 42px;
                display: block;
                padding-left: 2px;
            }
            
        }

        &__info{
            @extend .font-light ;
            width: 100%;
            color:$dark_color;
            font-style: italic;
            margin-bottom:20px;
        }
        
        &:nth-child(1){ .formStep__title{ &:before{ content: "1"; } } }
        &:nth-child(2){ .formStep__title{ &:before{ content: "2"; } } }
        &:nth-child(3){ .formStep__title{ &:before{ content: "3"; } } }
        &:nth-child(4){ .formStep__title{ &:before{ content: "4"; } } }
        &:nth-child(5){ .formStep__title{ &:before{ content: "5"; } } }
        &:nth-child(6){ .formStep__title{ &:before{ content: "6"; } } }
        
        .blocDoubleRoom, .blocSingleRoom, .blocBike{
            margin-top: 30px;
            
            h3{
                font-size: 16px;
                @extend .font-bold;
                color: $dark_color;
                margin-bottom: 15px;
            }
            
        }
            
        
    }
    
    .form-group--input{
        text-align: center;
    }
    
}