body.page-template-page-destination{
    .main-content{
        padding:0;
        
        .loading {
            background: url(../images/loading.gif) no-repeat;
            width: 30px;
            height: 30px;
            position: relative;
            left: 50%;
        }

        //======================HEAD PAGE======================/
        .contain-head-destpage{
            position:relative;
            overflow: hidden;
            &__text{
                position:absolute;
                top:30%;
                left:50%;
                transform:translateX(-50%);
                width:85%;
                z-index:1;
                &__title{
                    margin-bottom: 15px;
                    
                    color:$light_color;
                    line-height: 40px;
                    text-align: center;
                }
            }
            
            &__contain-image{
                max-height:250px;
                picture{
                    display:block;
                    max-height:250px;
                    overflow:hidden;
                    img{
                        width:100%;
                    }
                }
            }
        }
        
        //======================FILTER======================/
        .filter{
            //======================FILTER BY COUNTRY======================/
            &__countries{
                padding: 30px 15px 10px;
                max-width: 770px;
                margin: 0 auto;
                
                text-align: center;
                background:$light_color;
                &__country{
                    margin-bottom: 30px;


                    .item{
                        position: relative;
                        overflow: hidden;
                        display: inline-block;
                        padding:10px 20px;
                        margin-bottom: 15px;
                        min-width:200px;
                        border:1px solid $primary_color;
                        transition:all 0.3s;

                        &:before{
                            content:"";
                            position:absolute;
                            left:0;
                            top:0;
                            z-index:0;
                            width:100%;
                            height:100%;
                            background: $primary_color;
                            transform:translateY(-55px);
                            transition: all 0.3s;
                        }
                        &:hover, &:focus{
                            &:before{
                                transform:translateY(0px);
                            }
                            .label-container {
                                color:$light_color;
                            }
                        }


                        /* Customize the label (the container) */
                        .label-container {
                            display: inline-block;
                            position: relative;
                            padding-left: 35px;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            
                            color:$text_base_color;
                            text-transform: uppercase;
                            transition:all 0.3s;
                            
                            /* Hide the browser's default checkbox */
                            input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                            }

                            /* On mouse-over, add a grey background color */
                            &:hover input ~ .checkmark {
                                background-color: $light_color;
                            }

                            /* When the checkbox is checked, add a background */
                            input:checked ~ .checkmark {
                                background-color: $light_color;
                            }

                            /* Show the checkmark when checked */
                            input:checked ~ .checkmark:after {
                                display: block;
                            }

                            /* Style the checkmark/indicator */
                            .checkmark:after {
                                left: 7px;
                                top: -1px;
                                width: 9px;
                                height: 20px;
                                border: solid $primary_color;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 25px;
                            width: 25px;
                            background-color: $light_color;
                            border:1px solid $primary_color;

                            /* Create the checkmark/indicator (hidden when not checked) */
                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                        }
                    }

                    .custom-dropdown{
                        display: inline-block;
                        // border: 1px solid $primary_color;
                        // width: 160px;
                        // border-radius: 0px;
                        // overflow: hidden;
                        // background: $light_color url('../images/select_arrow_down.png') no-repeat 90% 50%;

                        .multiselect.dropdown-toggle {
                            border: 1px solid $dark_color;
                            width: 160px;
                            border-radius: 0px;
                            background: $light_color url('../images/arrow_down_dark.png') no-repeat 90% 50%;
                            .caret {
                                display: none;
                                // background: $light_color url('../images/select_arrow_down.png') no-repeat 90% 50%;                                
                            }
                        }

                        .multiselect-container.dropdown-menu {
                            border-radius: 0;
                            a {
                                outline: none;
                                padding: 3px 5px 3px 0;
                                label {
                                    padding: 3px 30px;
                                }
                                input {
                                    // display: none;
                                }
                            }
                            .active {
                                a {
                                    background-color: $dark_color;
                                }
                            }
                        }

                        
                        &.disabled {
                            opacity: .5;
                        }
                        

                        // select {
                        //     padding: 5px 15px 5px 10px;
                        //     width: 130%;
                        //     border: none;
                        //     box-shadow: none;
                        //     color:$color_dropdown_text;
                        //     background: transparent;
                        //     background-image: none;
                        //     -webkit-appearance: none;
                        // }

                        // select:focus {
                        //     outline: none;
                        // }
                    }
                    // &.selected, &:hover{
                    //     .item{
                    //         background:$primary_color;
                    //         label{
                    //             color:$light_color;
                    //         }
                    //     }
                    // }
                }
            }

            //======================SUBZONES FILTER======================/
            &__subzones{
                padding: 0 15px 10px;
                max-width: 770px;
                margin: 0 auto;
                color: $dark_color;
                display: flex;
                flex-wrap: wrap;
                .subzone__item {
                    margin-left: 30px;
                    margin-bottom: 5px;
                    .subzone__button {
                        display: inline-block;
                        background: url('../images/icon_close.png') no-repeat right center;
                        margin-left: 10px;
                        height: 9px;
                        width: 9px;
                        border: none;
                        outline: none;
                        &:hover {
                            opacity: .75;
                        }

                    }
                }


            }

            
            //======================ADVANCED FILTER======================/
            &__advanced{
                padding:15px;
                background: $dark_color;
                color:$light_color;
                box-shadow:0px 10px 0px 0px rgba($dark_color, 0.5);
                input, select{
                    color:$text_base_color;
                }
                
                /* Default opened filter part */
                &__default{
                    .nb-results{
                        text-align: left;
                        @include mobile {
                            text-align: center;
                        }
                        font-weight:bold;
                        text-transform: uppercase;
                    }
                    
                    .filter-open-btn{
                        // width: 100%;
                        text-align: center;
                        a{
                            display: inline-block;
                            padding-right:30px;
                            
                            color:$light_color;
                            text-decoration: none;
                            background: url('../images/select_arrow_down_white.png') no-repeat right center;
                        }
                        &.open{
                            a{
                                background-image: url('../images/select_arrow_up_white.png');
                            }
                        }
                    }
                    
                    .custom-dropdown{
                        position:relative;
                        display: inline-block;
                        border: 1px solid $light_color;
                        // width: 150px;
                        width: 200px;
                        @include mobile {
                            width: 100%;
                        }
                        border-radius: 0px;
                        overflow: hidden;
                        // background: red url('../images/select_arrow_down_white.png') no-repeat 90% 50%;
                        // background-size: 50px 50px;
                        select {
                            width: 100%;
                            // padding: 5px 15px 5px 15px;
                            padding: 2px 5px 2px 15px;
                            border: none;
                            box-shadow: none;
                            color: $light_color;
                            background: $dark_color url('../images/select_arrow_down_white.png') no-repeat;
                            background-position: right 10px top 8px;
                            // background-image: none;
                            -webkit-appearance: none;
                            appearance: none;
                        }

                        // &:before {
                        //     position: absolute;
                        //     content: '';
                        //     background: url('../images/select_arrow_down_white.png') no-repeat;
                        //     height: 16px;
                        //     width: 50px;
                        //     right: -30px;
                        //     top: 8px;
                        // }

                        select:focus {
                            outline: none;
                        }
                    }
                }
                
                /* Hidden filter part */
                &__hidden{
                    display:none;
                    margin-top:30px;
                    /*************** FILTER PART BY TYPE ************/
                    .filter-type{
                        margin-bottom:30px;
                        &__title{
                            text-transform: uppercase;
                        }
                        
                        /* Customize the label (the container) */
                        .label-container {
                            display: inline-block;
                            position: relative;
                            padding-left: 35px;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            
                            @extend .font-light;
                            color:$light_color;
                            transition:all 0.3s;

                            /* Hide the browser's default checkbox */
                            input {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                            }

                            /* On mouse-over, add a grey background color */
                            &:hover input ~ .checkmark {
                                background-color: $dark_color;
                            }

                            /* When the checkbox is checked, add a background */
                            input:checked ~ .checkmark {
                                background-color: $dark_color;
                            }

                            /* Show the checkmark when checked */
                            input:checked ~ .checkmark:after {
                                display: block;
                            }

                            /* Style the checkmark/indicator */
                            .checkmark:after {
                                left: 7px;
                                top: -1px;
                                width: 9px;
                                height: 20px;
                                border: solid $light_color;
                                border-width: 0 3px 3px 0;
                                -webkit-transform: rotate(45deg);
                                -ms-transform: rotate(45deg);
                                transform: rotate(45deg);
                            }
                        }

                        /* Create a custom checkbox */
                        .checkmark {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 25px;
                            width: 25px;
                            background-color: $dark_color;
                            border:1px solid $primary_color;

                            /* Create the checkmark/indicator (hidden when not checked) */
                            &:after {
                                content: "";
                                position: absolute;
                                display: none;
                            }
                        }
                    }
                    
                    /*************** FILTER PART BY DATE ************/
                    .filter-date{
                        margin-bottom:30px;
                        &__bloc{
                            padding-top:10px;
                            padding-bottom:20px;
                            &__title{
                                text-transform: uppercase;
                            }
                            &__date{
                                position: relative;
                                width: 75%;
                                @include mobile {
                                    width: 100%;
                                }
                                input {
                                    width: 100%;
                                    height: 40px;
                                    padding: 0 10px;
                                    border: none;
                                    outline: none;
                                    background: $light_color url('../images/icon_calendar.svg') no-repeat;
                                    background-size: 20px 20px;
                                    background-position: top 10px right 10px;
                                    
                                }
                            }
                        }
                        
                        
                        .ui-slider-handle {
                            outline:none;
                        }

                        #slider-range-length,
                        #slider-range-difficulty {
                            margin: 40px 0 20px;
                            @include mobile {
                                // margin: 40px 0 30px;
                            }
                        }

                        .noUi-target {
                            width: 75%;
                            @include mobile {
                                width: calc(100% - 80px);
                                margin-left: 40px !important;
                            }
                            height: 1px;
                            border: none;
                            border-radius: 0;
                            box-shadow: none;
                        }

                        .noUi-handle {
                            width: 10px;
                            height: 10px;
                            right: -5px;
                            background-color: $primary_color;
                            border-radius : 50%;
                            border: none;
                            box-shadow: none;
                            outline: none;
                            cursor: pointer;
                            &:before,
                            &:after {
                                content: none;
                            }
                            .noUi-tooltip {
                                background: transparent;
                                border: 0;
                                border-radius: 0;
                                box-shadow: none;
                                color: $light_color;
                            }

                            &-lower {
                                @include mobile {
                                    .noUi-tooltip {
                                        // transform: translate(0, 0);
                                    }
                                }
                            }
                            &-upper {
                                @include mobile {
                                    .noUi-tooltip {
                                        // transform: translate(-100%, 0);
                                        // bottom: -260%;
                                    }
                                }
                            }
                        }
                        
                    }
                    
                    /*************** FILTER PART BY AMBIANCE ************/
                    .filter-ambiance{
                        margin-bottom:30px;
                        &__bloc{
                            padding-top:10px;
                            padding-bottom:10px;
                            &__title{
                                text-transform: uppercase;
                            }
                            &__listambiance{
                                display:flex;
                                flex-flow: row wrap;
                                list-style:none;
                                padding:0;
                                li{
                                    width:calc(100% / 4);
                                    margin-bottom:15px;
                                    text-align:center;
                                }
                                a{
                                    display:inline-block;
                                    min-width:36px;
                                    padding-top:40px;
                                    color:$light_color;
                                    text-decoration: none;
                                    transition:all 0.3s;
                                    // &:hover, &:focus{
                                    //     color:$primary_color;
                                    //     &.selected {
                                    //         color:$light_color;
                                    //     }
                                    // }
                                    &.disabled {
                                        opacity: .5;
                                        color: $light_color;
                                    }

                                }
                                .family{
                                    a{
                                        background: url('../images/icon_ambiance_am_1.svg') no-repeat top center;
                                        background-size: 34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_1_over.svg');
                                        }
                                        
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_1_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_1.svg');
                                        // }
                                        
                                    }
                                }
                                .sport{
                                    a{
                                        background: url('../images/icon_ambiance_am_2.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_2_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_2_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_2.svg');
                                        // }
                                    }
                                }
                                .luxe{
                                    a{
                                        background: url('../images/icon_ambiance_am_3.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_3_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_3_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_3.svg');
                                        // }
                                    }
                                }
                                .ebike{
                                    a{
                                        background: url('../images/icon_ambiance_am_4.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_4_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_4_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_4.svg');
                                        // }
                                    }
                                }
                                .culture{
                                    a{
                                        background: url('../images/icon_ambiance_am_5.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_5_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_5_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_5.svg');
                                        // }
                                    }
                                }
                                .weekend{
                                    a{
                                        background: url('../images/icon_ambiance_am_6.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_6_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_6_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_6.svg');
                                        // }
                                    }
                                }
                                .wine{
                                    a{
                                        background: url('../images/icon_ambiance_am_7.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_ambiance_am_7_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_ambiance_am_7_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_ambiance_am_7.svg');
                                        // }
                                    }
                                }
                            }
                            &__listlandscape{
                                display:flex;
                                flex-flow: row wrap;
                                list-style:none;
                                padding:0;
                                li{
                                    width:calc(100% / 4);
                                    margin-bottom:15px;
                                    text-align:center;
                                }
                                a{
                                    display:inline-block;
                                    min-width:36px;
                                    padding-top:40px;
                                    color:$light_color;
                                    text-decoration: none;
                                    transition:all 0.3s;
                                    // &:hover, &:focus{
                                    //     color:$primary_color;
                                    // }
                                    &.disabled {
                                        opacity: .5;
                                        color: $light_color;
                                    }
                                }
                                .sea{
                                    a{
                                        background: url('../images/icon_landscape_la_1.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_landscape_la_1_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_landscape_la_1_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_landscape_la_1.svg');
                                        // }
                                    }
                                }
                                .nature{
                                    a{
                                        background: url('../images/icon_landscape_la_2.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_landscape_la_2_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_landscape_la_2_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_landscape_la_2.svg');
                                        // }
                                    }
                                }
                                .river{
                                    a{
                                        background: url('../images/icon_landscape_la_3.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_landscape_la_3_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_landscape_la_3_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_landscape_la_3.svg');
                                        // }
                                    }
                                }
                                .city{
                                    a{
                                        background: url('../images/icon_landscape_la_4.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_landscape_la_4_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_landscape_la_4_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_landscape_la_4.svg');
                                        // }
                                        
                                    }
                                }
                                .castle{
                                    a{
                                        background: url('../images/icon_landscape_la_5.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_landscape_la_5_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_landscape_la_5_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_landscape_la_5.svg');
                                        // }
                                    }
                                }
                                .greenway{
                                    a{
                                        background: url('../images/icon_landscape_la_6.svg') no-repeat top center;
                                        background-size:34px;
                                        &.selected, &:hover {
                                            background-image: url('../images/icon_landscape_la_6_over.svg');
                                        }
                                        // &:hover, &:focus, &.selected {
                                        //     background-image: url('../images/icon_landscape_la_6_over.svg');
                                        // }
                                        // &.disabled {
                                        //     background-image: url('../images/icon_landscape_la_6.svg');
                                        // }
                                    }
                                }
                            }
                        }
                    }
                    .link-reset{
                        text-align: center;
                        a{
                            text-decoration: underline;
                            color:$light_color;
                        }
                    }
                }
            }
        }
        
        .results{
            padding:40px 15px 30px 15px;
            .contain-results{
                max-width: 770px;
                margin:0 auto;
            }
            &__item{
                margin-bottom:40px;
                &__visual{
                    margin-bottom:25px;
                    picture{
                        display:block;
                        overflow:hidden;
                        box-shadow:10px 10px 0px 0px rgba($dark_color, 0.15);
                        img{
                            width:100%;
                            transition: transform 0.3s;
                            &:hover{
                                transform: scale(1.1);
                            }
                        }
                    }
                }
                &__text{
                    text-align:center;
                    .title{
                        margin-bottom: 15px;

                        color:$text_title_color;
                        font-weight: bold;
                        font-size:$font_base;
                    }
                    .description{
                        margin-bottom: 20px;

                        color:$text_title_color;
                    }
                    .price{
                        margin-bottom:30px;
                        &__from{
                            display:block;
                        }
                        &__number{
                            color:$primary_color;
                            font-weight:bold;
                            font-size:18px;
                        }
                    }
                    .link{
                        a{
                            display:inline-block;
                            min-width:250px;
                            padding:15px 0;

                            color:$text_base_color;
                            text-decoration: none;
                            text-transform: uppercase;
                            text-align:center;
                            border:1px solid $primary_color;
                            transition: all 0.3s;
                            &:hover, &:focus{
                                color:$light_color;
                            }
                            
                            position:relative;
                            overflow:hidden;
                            .innerText{
                                position:relative;
                                z-index:2;
                            }
                            &:before{
                                content:"";
                                position:absolute;
                                left:0;
                                top:0;
                                z-index:0;
                                width:100%;
                                height:100%;
                                background: $primary_color;
                                transform:translateY(-55px);
                                transition: all 0.3s;
                            }
                            &:hover, &:focus{
                                &:before{
                                    transform:translateY(0px);
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: 480px) {
            //======================HEAD PAGE======================/
            .contain-head-destpage{
                &__text{
                    top:45%;
                }
            }
        }
        
        @media (min-width: 600px) {
            .filter{
                &__countries{
                    display:flex;
                    &__country{
                        width:calc(100% / 3);
                        margin-bottom: 0px;
                        .item{
                            padding:10px 10px;
                            min-width:190px;
                        }
                    }
                }
                &__advanced{
                    &__default{
                        display: flex;
                        .nb-results{
                            width:47%;
                            margin-bottom:0;
                            margin-top:10px;
                        }
                        .filter-open-btn{
                            text-align: left;
                            width:15%;
                            margin-bottom:0;
                            margin-top:10px;
                        }
                        .contain-dropdown{
                            width:38%;
                            .dropdown-label{
                                font-size: $font_base;
                                font-weight: normal;
                                position:relative;
                                top:-5px;
                            }
                            .custom-dropdown{
                                position:relative;
                                top:5px;
                            }
                        }
                    }
                }
            }
            
            .results{
                padding:40px 15px 30px 15px;
                &__item{
                    display:flex;
                    &__visual{
                        width:50%;
                    }
                    &__text{
                        width:50%;
                        padding-left: 35px;
                        text-align:left;
                        .link{
                            a{
                                display:block;
                            }
                        }
                    }
                }
            }
        }
        
        @media (min-width: 768px) {
            //======================HEAD PAGE======================/
            .contain-head-destpage{

                &__contain-image{
                    max-height:310px;
                }
            }
            
            .filter{
                &__countries{
                }
                &__advanced{
                    &__hidden{
                        .special-toggle{
                            display: flex;
                        }
                        .filter-type{
                            width:27%;
                            padding-right:15px;
                        }
                        .filter-date{
                            width:33%;
                            padding-right:15px;
                        }
                        .filter-ambiance{
                            width:40%;
                        }
                    }
                }
            }
            
            .results{
                padding:40px 30px 30px 30px;
            }
        }
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
            
            .results{
                padding:40px 50px 30px 50px;
            }
        }
    }
    
    @media (min-width: 1300px) {
        main{
            overflow: hidden;
        }
    }
}