.listOrder{
    
    &__item{
        border-bottom: 1px solid $primary_color;
        padding: 0 0 20px;
        margin-bottom: 20px;
        display: flex;
        
        &:last-child{
            border-bottom: 0;
            padding: 0;
            margin-bottom: 0;
        }
        
        .info{
            width: calc(100% - 100px);
            
            h2{
                font-size: 18px;
                margin: 0 0 5px;
            }
            
            ul{
                margin: 0;
                padding-left: 20px;
            }
            
        }
        
        .action{
            width: 100px;
            
            .button{
                width: 100%;
                text-align: center;
                font-size: 14px;
                margin-top: 10px;
                
                &:first-child{
                    margin-top: 0;
                }
                
            }
            
            form{
                margin-top: 10px;
                .button{
                    background-color: $danger-color;
                    border: 1px solid $danger-color;

                    &:hover, &:focus{
                        background-color: #fff;
                        color: $danger-color;
                        border-color: $danger-color;
                    }

                }
            }
            
            
        }
                
    }
    
}