iframe.favshop{
    max-width: 950px;
    min-height: 200px;
    margin: 0 auto 40px;
    display: block;
}

.redirect_parent{
    cursor: pointer;
}

.empty_page{
    
    text-align: center;
    margin-bottom: 30px;
    
    p{
        font-size: 18px;
        padding: 20px 0;
    }
    
}