.modalInfo{
    
    position: fixed;
    top: 20px;
    left: 50%;
    width: 600px;
    max-width: 90%;
    padding: 10px 15px;
    background-color: $color_dropdown_text;
    color: $text_base_color;
    transform: translateX(-50%);
    
    &--error{
        background-color: $danger_color;
        color: #fff;
    }
    
    &--success{
        background-color: $primary_color;
        color: #fff;
    }
        
}