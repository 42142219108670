.menuAside {
    
    display:none;

    @media (min-width: 1100px) {
        position:fixed;
        top:0;
        left:0;
        width:250px;
        height:100vh;
        z-index:2000;
        padding:0;
        display:flex;
        flex-direction: column;

        box-shadow:0px 0 8px 0px $darkest_color;
        background:$light_color;

        ul, li{
            list-style:none;
        }
        a{
            text-decoration: none;
        }

        //*****************LOGO PART***********************/
        &__logo{
            width: 200px;
            height: 100px;
            margin:40px auto 70px auto;
        }
        .contain-logo{
            display:block;
        }

        .contain-menu{
            margin-bottom: 130px;
        }
        #aside-menu{
            text-transform: uppercase;
            padding-left:40px;
            li{
                position: relative;
                margin-bottom:10px;
                a{
                    color:$text_base_color;
                    &:hover{
                        font-weight:bold;
                    }
                }
                &:before{
                    content:"";
                    position:absolute;
                    left:-20px;
                    top:8px;
                    width:10px;
                    height:10px;
                    border:1px solid $primary_color;
                    border-radius:100%;
                }
            }
        }

        .contain-footer{
            position:absolute;
            width:100%;
            bottom:0;
        }

        //*****************LANG PART***********************/
        &__lang{
            margin-bottom:10px;
            ul{
                list-style:none;
                display:flex;
                padding:0 15px;

                text-align:center;
                li{
                    list-style:none;
                    width:calc(100% / 3);

                    border:1px solid $primary_color;
                    transition:all 0.3s;
                    a{
                        display:block;
                        padding:5px 0;

                        color:$color_text_lang;
                        transition:all 0.3s;
                    }
                    &:hover, &.active{
                        background: $primary_color;
                        a{
                            color:$light_color;
                        }
                    }
                    &:nth-child(2){
                        border-left:none;
                        border-right:none;
                    }
                }
            }
        }

        //*****************FOOTER ASIDE***********************/
        &__footer{
            position:relative;
            padding:25px 15px 15px 15px;

            color:$light_color;
            background: $dark_color;
            text-align: center;
            border-top:10px solid $border_color;
            &:before{
                position:absolute;
                content:"";
                top:0;
                left:0;
                height:1px;
                width:100%;

                background:$second_border_color;
            }
            a{
                color:$light_color;
            }
            &__contactBtn{
                display:inline-block;
                padding:10px 15px;
                margin-bottom: 10px;

                border: 1px solid $primary_color;
                text-transform: uppercase;
                transition:all 0.3s;
                &:hover, &:focus{
                    color:$dark_color;
                }
                
                position:relative;
                overflow:hidden;
                .innerText{
                    position:relative;
                    z-index:2;
                }
                
                &:before{
                    opacity: .5;
                }
                &:before, &:after{
                    position: absolute;
                    content: "";
                    display: block;
                    margin: -1px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;

                    background: $light_color;
                    transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                    transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                    transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                    transform-origin: top;
                    transform: scaleY(0);
                }

                &:hover, &:focus{
                    &:before{
                        transform: scaleY(1);
                        transition-duration: 80ms;
                    }
                    &:after{
                        transform: scaleY(1);
                        transition-duration: 250ms;
                    }
                }
            }
            &__phoneNumber a{
                transition:all 0.3s;
                &:hover, &:focus{
                    color:$primary_color;
                }
            }
            &__infos{
                font-size:10px;
            }
        }
    }
}