// == Colors (better to use $name_name than $name-name)
$primary_color: #53bfa4; //vert clair

$dark_color: #5d5a54;
$light_color: #fff;
$darkest_color: #000;
$color_text_lang:#adadad;
$color_dropdown_text:#b9b8b6;

$danger-color: #f05861;

$text_title_color: $dark_color;
$text_base_color: #333;

$border_color: #aeaca9;
$second_border_color: #8e8b87;


//== Typography variable

$font_base: 16px;
$font_mobile_base: $font_base - 2px;

$font_title: 25px;
$font_mobile_title: $font_title - 3px;

$font_small_title: 22px;
$font_mobile_small_title: $font_small_title - 2px;

$font_big_title: 35px;
$font_mobile_big_title: $font_big_title - 10px;


//== Screen width
$min-xs: $screen-xs-min;
$max-xs: $screen-xs-max;
$min-sm: $screen-sm-min;
$max-sm: $screen-sm-max;
$min-md: $screen-md-min;
$max-md: $screen-md-max;
$min-lg: $screen-lg-min;

