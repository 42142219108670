.fav-loader{
    
    padding: 60px 0;
    transition: all 0.2s ease;
    overflow: hidden;
    
    &.fadeout{
        max-height: 0;
        opacity: 0;
        padding: 0;
        visibility: hidden;
    }
    
    &--full{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.5);
        top: 0;
        left: 0;
        transition: all 0.3s ease;
        z-index: 500;
        
        .spinner{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        
    }
    
    .spinner {
      margin: 0 auto;
      width: 100px;
      text-align: center;
    }

    .spinner > div {
      width: 20px;
      height: 20px;
      background-color: $primary_color;
      margin: 0 5px;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .spinner .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .spinner .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%, 80%, 100% { -webkit-transform: scale(0) }
      40% { -webkit-transform: scale(1.0) }
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% { 
        -webkit-transform: scale(0);
        transform: scale(0);
      } 40% { 
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
    }
    
}