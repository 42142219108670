body.page-template-page-checkout{
    
    .main-content{
        padding: 60px 0 0 0;
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
        
        h1{
            text-align: center;
            margin: 40px 0 30px;
            
            @include tablet{
                margin: 50px 0 40px;
            }
        }
        
    }
    
}

.checkout{
    padding: 10px 15px 0;
    
    &__userInfo{
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $primary_color;
        
        h2{
            margin-bottom: 15px;
        }
        
        p:not(.date){
            margin-bottom: 4px;
            padding-left: 10px;
        }
        
        .cart{
            &__total{
                margin-top: 20px;
            }
        }
        
    }
    
    .shop-form{
            
        .labelTitle{
            @extend .font-bold;
        }
        
        .options{
            
            display: flex;
            justify-content: space-around;
            flex-flow: wrap;
            
        }
        
        .condition{
            text-align: center;
            margin-top: 15px;
            
            label{
                a{
                    color: $primary_color;
                    cursor: pointer;
                }
            }
            
        }
        
        .form-group{
            
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                
                li{
                    margin-bottom: 5px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                
            }
            
            &--input{
                margin-top: 30px;
                text-align: center;
            }
            
        }

    }
    
}

.confirm{
    padding-bottom: 20px;
    
    &__bloc{
        max-width: 400px;
        padding: 20px;
        background-color: #fff;
        margin: 0 auto 30px;
        
        @media(min-width:450px){
            border: 1px solid $primary_color;
            box-shadow: 10px 10px 0 0 rgba(93,90,84,.15);
        }
        
        @media(min-width:600px){
            max-width: 550px;
            border: 1px solid $primary_color;
            box-shadow: 10px 10px 0 0 rgba(93,90,84,.15);
        }
        
        h2{
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $primary_color;
            text-align: center;
        }
        
        .action{
            text-align: center;
            margin-top: 20px;
        }
        
    }
    
}


