body.page-template-page-login, body.page-template-page-register, body.page-template-page-reset-password{
    
    .main-content{
        padding: 60px 0 0 0;
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
        
        h1{
            text-align: center;
            margin: 40px 0 30px;
            
            @include tablet{
                margin: 50px 0 40px;
            }
        }
        
    }
    
}


.login{
    padding-bottom: 20px;
    
    &__bloc{
        
        max-width: 400px;
        padding: 20px;
        background-color: #fff;
        margin: 0 auto 30px;
        
        @media(min-width:420px){
            border: 1px solid $primary-color;
            box-shadow: 10px 10px 0 0 rgba(93,90,84,.15);
        }
        
        .form-group{
            
            &--input{
                text-align: center;
                margin: 0;
                
                a{
                    display: inline-block;
                    margin-top: 10px;
                    color: $primary-color;
                    cursor: pointer;
                }
                
            }
            
        }
        
        &--register{
            
            text-align: center;
            
            p{
                @extend .font-light;
                margin-bottom: 20px;
            }
            
        }
        
    }
    
}