body.page-template-default, body.post-template-default{
    .main-content{
        padding:0;
        
        //======================HEAD PAGE======================/
        .contain-head-genericpage{
            position:relative;
            overflow: hidden;
            &__text{
                position:absolute;
                top:50%;
                left:50%;
                transform:translateX(-50%);
                width:85%;
                z-index:1;
                &__title{
                    margin-bottom: 15px;
                    
                    color:$light_color;
                    line-height: 40px;
                    text-align: center;
                }
            }
            
            &__contain-image{
                // max-height:320px;
                max-height:520px;
                picture{
                    display:block;
                    // max-height:320px;
                    max-height:520px;
                    overflow:hidden;
                    img{
                        width:100%;
                    }
                }
            }
        }
        
        .content-type{
            max-width: 760px;
            margin: 0 auto;
            &__row{
                padding:25px 0px 0px 0px;
                overflow: hidden;
                &:first-child{
                    padding-top: 40px;
                }
            }
            .col1, .col2{
                padding:0;
            }
            &__subtitle{
                padding:0px 15px 30px 15px;
            }
            &__item{
                padding:0px 15px 15px 15px;
            }
            &__text{
                h3 {
                  padding-bottom: 15px;  
                }
                a{
                    color:$primary_color;
                    text-decoration: underline;
                    transition: all 0.3s;
                    &:hover, &:focus{
                        color:$text_base_color;
                    }
                }
                p, ul, table{
                    @extend .font-light;
                }
            }
            &__image{
                display: block;
                width: 100%;
                max-width:600px;
                margin:0 auto;
                overflow: hidden;
                margin-bottom: 20px;
                box-shadow: 10px 10px 0 0 rgba($dark_color,.15);
                img{
                    width:100%;
                    // transition: transform 0.3s;
                    &:hover{
                        // transform: scale(1.1);
                    }
                }
            }
            &__button{
                margin:15px 0;
                &__link{
                    text-align:center;
                    a{
                        display:inline-block;
                        min-width:250px;
                        padding:15px 0;

                        color:$text_base_color;
                        text-decoration: none;
                        text-transform: uppercase;
                        text-align:center;
                        border:1px solid $primary_color;
                        transition: all 0.3s;
                        &:hover, &:focus{
                            color:$light_color;
                        }
                        
                        position:relative;
                        overflow:hidden;
                        .innerText{
                            position:relative;
                            z-index:2;
                        }
                        
                        &:before{
                            opacity: .5;
                        }
                        &:before, &:after{
                            position: absolute;
                            content: "";
                            display: block;
                            margin: -1px;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 1;

                            background: $primary_color;
                            transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                            transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                            transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                            transform-origin: top;
                            transform: scaleY(0);
                        }

                        &:hover, &:focus{
                            &:before{
                                transform: scaleY(1);
                                transition-duration: 80ms;
                            }
                            &:after{
                                transform: scaleY(1);
                                transition-duration: 250ms;
                            }
                        }
                    }
                }
            }
            &__video{
                margin-bottom:30px;
                iframe{
                    display: block;
                    width:100%;
                    min-height: 200px;
                    max-width: 700px;
                    margin: 0 auto;
                    box-shadow: 10px 10px 0 0 rgba($dark_color,.15);
                }
            }
            &__quote{
                &:before{
                    content:"";
                    position:relative;
                    float: left;
                    display:block;
                    width:50px;
                    height:25px;
                    background: url(../images/quote_green.svg) no-repeat;
                }
                .contentText{
                    font-style: italic;
                }
                .user{
                    float:right;
                    color:$primary_color;
                    font-weight:bold;
                }
            }
            table{
                width:100%;
            }
            table, th, td{
                border:1px solid $primary_color;
            }

            &__destination-link{
                text-align: center;
            }

        }
        
        @media (min-width: 480px){
            .content-type{
                &__video{
                    iframe{
                        min-height: 300px;
                    }
                }
            }
        }
        
        @media (min-width: 600px){
            .content-type{
                &__row{
                    margin-bottom: 30px;
                }
                &__video{
                    iframe{
                        min-height: 350px;
                    }
                }
            }
        }
        
        @media (min-width: 768px){
            .content-type{
                &__row{
                    &:first-child{
                        padding-top: 50px;
                    }
                }
                &__video{
                    iframe{
                        min-height: 400px;
                    }
                }
            }
        }
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
        
        @media (min-width: 1300px){
            .content-type{
                max-width: 900px;
            }
        }
    }
}