footer.main {
    padding:0;
    text-align:center;
    
    .social-news{
        padding:30px 15px;
        
        color:$light_color;
        background:$dark_color;
        .subcontain-social-news{
            max-width:1000px;
        }
        &__newsletter{
            &__title{
                margin-bottom: 20px;
            }
            &__intro{
                margin-bottom: 0;
                @extend .font-light;
            }
            &__form{
                position:relative;
                top:-5px;
            }
        }
        
        &__social{
            &__title{
                margin-bottom: 20px;
                padding-bottom:50px;
                background: url(../images/logo_velo_footer.svg) bottom center no-repeat;
                background-size:60px;
            }
            &__network{
                display:flex;
                justify-content: center;
                max-width:165px;
                margin:0 auto;
                padding:0;
                text-align: center;
                list-style: none;
                li{
                    width:calc(100% / 4);
                    position:relative;
                }
                a{
                    display: block;
                    width:34px;
                    height:34px;
                    text-indent:-9999pt;
                    position:relative;
                    left:50%;
                    transform:translateX(-50%);
                    transition:all 0.3s;
                    &:hover, &:focus{
                        transform: translateX(-50%) scale(0.9);
                    }
                }
                .instagram,
                .youtube,
                .facebook,
                .google {
                    &:before {
                        position: absolute;
                        content: "";
                        background: $primary_color;
                        // background-size: 34px 34px;
                        border-radius: 50%;
                        width:34px;
                        height:34px;
                        transform:translateX(-50%);
                    }
                }

                .instagram a{
                    background: url(../images/logo_instagram.svg) no-repeat;
                    
                }
                .youtube a{
                    background: url(../images/logo_youtube.svg) no-repeat;
                    
                }
                .facebook a{
                    background: url(../images/logo_facebook.svg) no-repeat;
                    
                }
                .google a{
                    background: url(../images/logo_google.svg) no-repeat;
                }
                .pinterest a{
                    background: url(../images/logo_pinterest.svg) no-repeat;
                }
            }
        }
    }
    
    .payment-method{
        margin: 0 15px;
        padding:30px 15px 0px 15px;
        background: $light_color;
        &__list{
            max-width: 750px;
            margin:0 auto;
            padding:0;
            list-style: none;
            li{
                margin-bottom: 20px;
                p{
                    margin-bottom: 0;
                }
                .visual{
                    display: block;
                    margin-bottom:15px;
                    img{
                        width:50px;
                        height:50px;
                    }
                }
                .title{
                    text-transform: uppercase;
                }
                .cards{
                    display: inline-block;
                    .mastercard{
                        width:30px;
                        height:20px;
                    }
                    .visa{
                        width:30px;
                        height:20px;
                    }
                }
                .phoneNumber{
                    a{
                        text-decoration: none;
                        color:$text_base_color;
                    }
                }
                .infos{
                    font-size:10px;
                }
                &:last-child{
                    padding-bottom: 15px;
                    margin-bottom: 0px;
                    border-bottom:1px solid $primary_color;
                }
            }
        }
    }
    
    .footer-menu{
        padding:30px 15px;
        text-align:left;
        background: $light_color;
        .subcontain-menu{
            max-width:1000px;
            margin:0 auto;
        }
        &__listZone{
            a{
                color:$text_base_color;
            }
            h3{
                margin-bottom: 15px;

                font-size:16px;
                a{
                    display: block;
                    max-width:230px;
                    .arrow{
                        display: inline-block;
                        width:16px;
                        height:10px;
                        float:right;
                        background:url(../images/arrow_down_dark.png) no-repeat right center;
                        transform: rotate(90deg);
                        transition:all 0.3s;
                        &.open{
                            transform: rotate(0deg);
                        }
                    }
                }
            }
            ul{
                display: none;
                list-style:none;
                padding:0;
                font-size:14px;
                @extend .font-light;
            }
        }
        &__menu{
            font-size:14px;
        }
        a{
            color:$text_base_color;
        }
        #footer-menu{
            padding:0;
            margin-bottom: 30px;
            list-style:none;
            @extend .font-light;
        }
        &__link{
            a{
                display:block;
                padding:15px 0;

                color:$text_base_color;
                text-decoration: none;
                text-transform: uppercase;
                text-align:center;
                border:1px solid $primary_color;
                transition: all 0.3s;
                &:hover, &:focus{
                    color:$light_color;
                }
                
                position:relative;
                overflow:hidden;
                .innerText{
                    position:relative;
                    z-index:2;
                }
                
                &:before{
                    opacity: .5;
                }
                &:before, &:after{
                    position: absolute;
                    content: "";
                    display: block;
                    margin: -1px;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;

                    background: $primary_color;
                    transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                    transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                    transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                    transform-origin: top;
                    transform: scaleY(0);
                }

                &:hover, &:focus{
                    &:before{
                        transform: scaleY(1);
                        transition-duration: 80ms;
                    }
                    &:after{
                        transform: scaleY(1);
                        transition-duration: 250ms;
                    }
                }
            }
        }
    }
    
    @media (min-width: 480px) {
        .payment-method{
            padding-bottom: 15px;
            border-bottom:1px solid $primary_color;
            &__list{
                display:flex;
                li{
                    width: calc(100% / 3);
                    &:last-child{
                        padding-bottom: 0px;
                        border-bottom:none;
                    }
                }
            }
        }
    }
    
    @media (min-width: 768px) {
        .social-news{
            box-shadow:0px -10px 0px 0px rgba($dark_color, 0.5);
            .subcontain-social-news{
                display:flex;
                margin:0 auto;
            }
            &__newsletter{
                width:75%;
                padding-right:15px;
                text-align: left;
            }
            &__social{
                width:25%;
                &__title{
                    padding-bottom:70px;
                    background-size:80px;
                    @include specific-ie{
                        background-size:160px;
                    }
                }
            }
        }
        
        .footer-menu{
            padding:30px 15px 260px 15px;
            background: $light_color url(../images/background_footer.png) no-repeat bottom center;
            .contain-link-menu{
                display:flex;
            }
            &__listZone{
                padding-right: 5px;
                h3 a .arrow{
                    display: none;
                }
                ul{
                    display: block;
                }
            }
            .zone-french{
                width:calc(100% / 4);
                min-width:280px;
                ul{
                    max-height: 300px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                }
            }
            .zone-europe{
                width:calc(100% / 4);
                min-width:140px;
            }
            .zone-world{
                width:calc(100% / 4);
                min-width: 170px;
            }
            &__menu{
                width:calc(100% / 4);
                min-width: 160px;
            }
            &__link{
                text-align: center;
                a{
                    display:inline-block;
                    padding:15px 45px;
                }
            }
        }
    }
    
    @media (min-width: 1100px) {
        width:calc(100% - 250px);
        margin:0;
        margin-left:250px;
        .footer-menu{
            
        }
    }

    .reviews {
        background-color: $primary_color;
        padding: 0 15px;
        @media (min-width: 768px) {
            padding: 30px 15px 15px;
        }
    }
}