//== Breakpoints

@mixin desktop {
    @media (min-width: $min-lg) {
        @content;
    }
}

@mixin laptop {
    @media (min-width: $min-md) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: $min-sm) {
        @content;
    }
}

@mixin tablet-only {
    @media (min-width: $min-sm) and (max-width: $max-sm) {
        @content;
    }
}

//Max width 767px
@mixin mobile {
    @media (max-width: $max-xs) {
        @content;
    }
}

@mixin tablet-min {
    @media (max-width: $min-sm) {
        @content;
    }
}

//Max width 991px
@mixin tablet-max {
    @media (max-width: $max-sm) {
        @content;
    }
}

//Min width 
@mixin mobile-min {
    @media (min-width: $min-xs) {
        @content;
    }
}

//Max width 479px
@mixin small-mobile{
    @media (max-width: $max-xss) {
        @content;
    }
}

@mixin small-desktop-screen{
    @media (max-width: 1100px){
        @content;
    }
}

@mixin large-desktop-screen{
    @media (min-width: $min-lg){
        @content;
    }
}

@mixin placecolor($colorText){
    &::-webkit-input-placeholder {  //Chrome/Opera/Safari 
        color: $colorText;
    }
    &::-moz-placeholder {  //Firefox 19+ 
        color: $colorText;
    }
    &:-ms-input-placeholder {  //IE 10+ 
        color: $colorText;
    }
    &:-moz-placeholder {  //Firefox 18- 
        color: $colorText;
    }
}

@mixin specific-ie{
    @media all and (-ms-high-contrast:none){//@media screen and (min-width:0\0) {
        @content;
    }
}

@mixin btn-link($bottom, $sizeWidth, $colorText){
    &__link{
        position:absolute;
        bottom:$bottom;
        margin-bottom: 0;
        width:$sizeWidth;
        a{
            display:block;
            padding:15px 0;

            color:$colorText;
            text-decoration: none;
            text-transform: uppercase;
            text-align:center;
            border:1px solid $primary_color;
            transition: all 0.3s;
            &:hover, &:focus{
                color:$light_color;
            }
            
            
            position:relative;
            overflow:hidden;
            .innerText{
                position:relative;
                z-index:2;
            }
            
            &:before{
                opacity: .5;
            }
            &:before, &:after{
                position: absolute;
                content: "";
                display: block;
                margin: -1px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;

                background: $primary_color;
                transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                transform-origin: top;
                transform: scaleY(0);
            }

            &:hover, &:focus{
                &:before{
                    transform: scaleY(1);
                    transition-duration: 80ms;
                }
                &:after{
                    transform: scaleY(1);
                    transition-duration: 250ms;
                }
            }
        }
    }
}