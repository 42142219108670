// Fix image url for prev and next buttons of Jquery-UI datepicker, cf ticket n°26882
.ui-widget-header .ui-icon {
    background-image: url('../images/ui-icons_444444_256x240.png') !important;
}

.shop-form{
    
    label{
        font-size: 16px;
        @extend .font-light;
        color: $dark_color;
    }
    
    select{
        border: 1px solid $dark_color;
        padding: 10px 30px 10px 15px;
        height: 42px;
        font-size: 14px;
        color: $dark_color;
        @extend .font-light;
        transition: all 0.2s ease;
        outline: 0;
        border-radius: 0;
        
        &:focus{
            border-color: $primary_color;
        }
        
    }
    
    input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="tel"], input[type="date"]{
        border: 1px solid $dark_color;
        padding: 10px 15px;
        height: 42px;
        font-size: 14px;
        color: $dark_color;
        @extend .font-light;
        border-radius: 0;
        box-shadow: none;
        transition: all 0.2s ease;
        outline: 0;
        
        &:focus{
            border-color: $primary_color !important;
        }
        
    }

	// Jquery-UI date-picker altField, cf ticket n°26882
    input[type="date"].alt-date-field{
    	display: none;
    }
    
    .passwordInput{
        label{
            small{
                font-size: 12px;
                font-style: italic;
            }
        }
    }

    input[type="checkbox"]{

        visibility: hidden; 
        position: absolute;
        
        + label{

            padding-left: 0px;

            &:before{
                content: '';
                width: 28px;
                height: 28px;
                display: inline-block;
                font-size: 18px;
                background-color: $light-color;
                border: 2px solid $primary_color;
                float: left;
                margin: -2px 10px 0 0;
            }

        }

        &:checked, &[readonly=readonly]{

            + label{
                &:before{
                    background-color: $primary_color;
                }
            }

        }

    }
    
    input[type="radio"]{

        visibility: hidden; 
        position: absolute;
        
        + label{

            padding-left: 0px;

            &:before{
                content: '';
                width: 20px;
                height: 20px;
                display: inline-block;
                font-size: 18px;
                background-color: $light-color;
                border: 2px solid $primary_color;
                float: left;
                margin: 1px 8px 0 0;
                border-radius: 100%;
            }

        }

        &:checked, &[readonly=readonly]{

            + label{
                &:before{
                    background-color: $primary_color;
                }
            }

        }

    }

    /*input[type="submit"], {
        
        border-radius: 0;
        box-shadow: none;
        border: 0;
        background-color: $primart-color;
        
    }*/
    
    .align_field{
        display: flex;
        align-items: flex-start;
        
        .form-group{
            margin-right: 25px;
            
            &:last-child{
                margin-right: 0;
            }
            
        }
        
        &.info_traveler{
            
            margin-bottom: 15px;
            flex-flow: wrap;
            
            .form-group{
                margin-right: 0;
                
                
                select{
                    padding-right: 15px;
                    width: 90px;
                }
                
                width: 100%;
                
                @media (min-width: 450px){
                    padding-right: 20px;
                    
                    &:nth-child(1){
                        width: auto;
                    }
                    
                    &:nth-child(2){
                        width: calc(100% - 110px);
                    }

                    &:nth-child(3){
                        width: 60%;
                    }

                    &:nth-child(4){
                        width: 40%;
                    }
                }
                
                @media (min-width: 700px){
                    &:nth-child(2), &:nth-child(3){
                        width: 30%;
                    }

                    &:nth-child(4){
                        padding-right: 0;
                        width: calc(40% - 110px);
                    }
                }
                
            }
            
        }
        
        &.info_traveler_plus{
            
            margin-bottom: 0;
            flex-flow: wrap;
            
            .form-group{
                margin-right: 0;
                
                select{
                    padding-right: 15px;
                    width: 160px;
                }
                
                width: 100%;
                
                @media (min-width: 450px){
                    padding-right: 20px;
                    
                    &:nth-child(1){
                        width: auto;
                    }
                    
                    &:nth-child(2){
                        width: calc(100% - 180px);
                    }

                    &:nth-child(3){
                        width: 60%;
                    }

                    &:nth-child(4){
                        width: 40%;
                    }
                }
                
                @media (min-width: 800px){
                    &:nth-child(2), &:nth-child(3){
                        width: 28%;
                    }

                    &:nth-child(4){
                        padding-right: 0;
                        width: calc(44% - 180px);
                    }
                }
                
            }
            
        }
        
        &.option_check{
            flex-flow: wrap;
            
            .check_container{
                padding-right: 20px;
                &:last-child{
                    padding-right: 0;
                }
            }
            
        }
        
        &.info_bike{
            
            margin-bottom: 15px;
            flex-flow: wrap;
            
            .form-group{
                margin-right: 0;
                
                select{
                    padding-right: 15px;
                }
                
                &:nth-child(1){
                    select{
                        width: 255px;
                    }
                }
                
                &:nth-child(2){
                    select{
                        width: 115px;
                    }
                }
                    
                &:nth-child(3){
                    input{
                        width: 140px;
                    }
                }
                
                width: 100%;
                
                @media (min-width: 450px){
                    padding-right: 20px;
                    
                    &:nth-child(1){
                        width: 70%;
                    }
                    
                    &:nth-child(2){
                        width: 30%;
                    }
                    
                    &:nth-child(3){
                        width: auto;
                    }

                    &:nth-child(4){
                        padding-right: 0;
                        width: calc(100% - 160px);
                    }
                }
                
                @media (min-width: 800px){
                    &:nth-child(1), &:nth-child(2), &:nth-child(3){
                        width: auto;
                    }

                    &:nth-child(4){
                        width: calc(100% - 570px);
                    }
                }
                
            }
            
        }
        
        &.list_accessory{
            flex-flow: wrap;
            
            .form-group{
                margin-right: 0;
                width: 100%;
                
                @media (min-width: 420px){
                    padding-right: 20px;
                    width: 50%;

                    &:nth-child(2){
                        padding-right: 0;
                    }

                    &:last-child{
                        padding-right: 0;
                    }
                }
                
                @media (min-width: 700px){
                    width: 25%;
                    &:nth-child(2){
                        padding-right: 20px;
                    }
                }
                
            }
            
        }
        
        &.containerHelmet{
            flex-flow: wrap;
            
            .blocHelmet{
                padding-right: 20px;
                &:last-child{
                    padding-right: 0;
                }
            }
        }
        
        &.list_transfert{
            flex-flow: wrap;
            
            .form-group{
                padding-right: 20px;
                &:last-child{
                    padding-right: 0;
                }
            }
        }
        
    }
    
}

.has-error .help-block{
    color: $danger_color;
}