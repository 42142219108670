.cart{
    padding: 0 15px;
    
    &__header{
        
        display: none;
        border-bottom: 1px solid $primary-color;
        margin-bottom: 20px;
        padding-bottom: 5px;
        
        .columnTitle{
            padding: 0 10px;
            
            font-size: 16px;
            @extend .font-bold;
            color: $dark-color;
            
            &:nth-child(1){ width: 70%; }
            &:nth-child(2){ width: 20%; text-align: center;}
            &:nth-child(3){ width: 10%; text-align: right; }
            
        }
        
        @media(min-width:550px){
            display: flex;
        }
        
    }
    
    &__product{
        display: block;
        border-bottom: 1px solid $primary-color;
        margin-bottom: 20px;
        padding-bottom: 20px;
        
        @media(min-width:550px){
            display: flex;
        }
        
        &__info, &__price, &__action{
            @media(min-width:550px){
                padding: 0 10px;
            }
        }
        
        &__info{
            @media(min-width:550px){
                width: 70%;
            }
            
            h2{
                color: $text_base_color;
                font-size: 20px;
                @extend .font-bold;
                
            }
            
            h2, h3, strong{
                a{
                    color: $primary_color;
                }
            }
            
            .date{
                font-size: 16px;
                color: $dark-color;
            }
            
            ul{
                margin-bottom: 0;
                font-size: 15px;
                line-height: 1.3;
                color: $primary-color;
                padding-left: 22px;
                @extend .font-bold;
                
                span{
                    font-size: 16px;
                    color: $dark-color;
                    @extend .font-light;
                }
                
            }
            
            &.couponInfo{
                
                p{
                    font-size: 16px;
                    color: $dark-color;
                    margin-bottom: 8px;
                }
                
                h2{
                    color: $text_base_color;
                    font-size: 16px;
                    @extend .font-bold;
                }
                
            }
            
        }
        
        &__price{
            font-size: 16px;
            color: $dark-color;
            @extend .font-bold;
            @media(min-width:550px){
                width: 20%;
                text-align: center;
            }
        }
        
        &__action{
            
            @media(min-width:550px){
                width: 10%;
                text-align: right;
            }
        }
        
        .cartHeaderMobile{
            text-decoration: underline;
            text-decoration-color: $primary-color;
            color: $dark-color;
            @extend .font-bold;
            margin: 20px 0 8px;
            
            @media(min-width: 550px){
                display: none;
            }
            
        }
        
        &--obsolete{
            color: $danger-color;
        }
        
    }
    
    &__coupon{
        padding: 0 15px;
        text-align: center;
        
        @media(min-width:550px){
            text-align: left;
        }
        
        input[type="text"]{
            width: 180px;
            border: 1px solid $dark_color !important;
            padding: 10px 15px;
            height: 42px;
            font-size: 14px;
            color: $dark_color;
            @extend .font-light;
            border-radius: 0;
            box-shadow: none;
            outline: 0;
            transition: all 0.2s ease;
            
            &:focus{
                border-color: $primary_color !important;
            }
            
        }
        
        input[type="submit"]{
            
            height: 42px;
            border: 1px solid $primary_color !important;
            padding: 0 15px !important;
            text-transform: none !important;
            
            @extend .button--shop;
        }
        
        .couponError{
            color: $danger-color;
        }
        
        
        
    }
    
    &__total{
        text-align: center;
        margin-top: 40px;
        
        &__label, &__value{
            font-size: 22px;
            @extend .font-bold;
            color: $primary-color;
        }
        
        &__checkout{
            margin-top: 10px;
            
            .button{
                padding: 15px 70px;
                
                @media(min-width: 600px){
                    padding: 17px 100px;
                }
                
            }
            
        }
        
    }
    
}