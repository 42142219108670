body.home {
	background: $light_color;
   

    .main-content{
        padding:0;
        div{
            padding:0;
        }
        
        //======================HEAD PAGE======================/
        .contain-head-page{
            position:relative;
            overflow: hidden;
            &__text{
                position:absolute;
                top:15%;
                left:50%;
                transform:translateX(-50%);
                width:85%;
                z-index:1;

                color:$light_color;
                &__titles{
                    position:relative;
                    text-align: center;
                    .title{

                        margin-bottom:15px;
                        line-height:40px;
                    }
                    .subline1, .subline2{
                        display:none;
                        @extend .font-light;
                    }
                }
                &__travel-choice{
                    position:relative;
                    padding:15px 25px;
                    max-width: 700px;
                    margin: 0 auto;
                    
                    font-size:$font_base;
                    background: $light_color;
                    box-shadow:10px 10px 0px 0px rgba($light_color, 0.5);
                    ul{
                        padding:0;
                        margin:0;
                        list-style:none;
                        text-align:center;
                        li{
                            text-transform:uppercase;
                            &.travel{
                                margin-bottom: 20px;
                                color:$text_base_color;
                            }
                            &.oneitem{
                                a{
                                    display: inline-block;
                                    width:100%;
                                    padding:15px 40px;

                                    color:$text_base_color;
                                    border:1px solid $primary_color;
                                    text-decoration: none;
                                    transition:all 0.3s;
                                    &:hover, &:focus{
                                        color:$light_color;
                                        /*background: $primary_color;*/
                                    }
                                    
                                    position:relative;
                                    overflow:hidden;
                                    .innerText{
                                        position:relative;
                                        z-index:2;
                                    }
                                    
                                    &:before{
                                        opacity: .5;
                                    }
                                    &:before, &:after{
                                        position: absolute;
                                        content: "";
                                        display: block;
                                        margin: -1px;
                                        top: 0;
                                        right: 0;
                                        bottom: 0;
                                        left: 0;
                                        z-index: 1;
                                        
                                        background: $primary_color;
                                        transition: -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                                        transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1);
                                        transition: transform 150ms cubic-bezier(0.75, 0, 0.125, 1), -webkit-transform 150ms cubic-bezier(0.75, 0, 0.125, 1);

                                        transform-origin: top;
                                        transform: scaleY(0);
                                    }
                                    
                                    &:hover, &:focus{
                                        &:before{
                                            transform: scaleY(1);
                                            transition-duration: 80ms;
                                        }
                                        &:after{
                                            transform: scaleY(1);
                                            transition-duration: 250ms;
                                        }
                                    }
                                    
                                }
                                &:nth-child(3) a{
/*                                    border-top:none;
                                    border-bottom:none;*/
                                }
                            }
                            &.smallmap{
                                display:none;
                            }
                        }
                    }
                }
            }
            &__contain-images{
                position:relative;
                overflow:hidden;
                max-height:430px;
                &__image{
                    picture{
                        display: block;
                        img{
                            width:100%;
                        }
                    }
                    .caption{
                        display:none;
                        @extend .font-light;
                    }
                }
                &__video {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        
        //======================HIGHLIGHTED TRIP======================/
        .contain-hightlight-trip{
            max-width: 1100px;
            margin: 0 auto;
            padding:35px 15px 50px 15px;
            background:$light_color;
            
            &__title, &__intro{
                max-width: 600px;
                margin: 0 auto 20px;
                text-align: center;
            }
            
            &__intro{
                margin: 0 auto 30px;
            }
            
            &__trip{
                position:relative;
                padding-bottom: 75px;
                margin-bottom: 50px;
                text-align:center;

                a, a:hover {
                   text-decoration: none;
                    color: $text_base_color;
                }

                &:hover {
                    // border: 1px dotted red;
                    picture {
                        img { 
                            transform: scale(1.1);
                        }
                    }

                    .contain-hightlight-trip__trip__link { 
                        a {
                            color:$light_color;
                            &:before{
                                transform: scaleY(1);
                                transition-duration: 80ms;
                            }
                            &:after{
                                transform: scaleY(1);
                                transition-duration: 250ms;
                            }
                        }
                    }
                }

                &__picture{
                    margin-bottom:0px;
                    picture{
                        display:block;
                        overflow: hidden;
                        box-shadow:10px 10px 0px 0px rgba($dark_color, 0.15);
                        img{
                            width:100%;
                            transition: transform 0.3s;
                            // &:hover{
                            //     transform: scale(1.1);
                            // }
                        }
                    }
                    .legend{
                        position:relative;
                        display:inline-block;
                        top:-10px;
                        padding:3px 8px;
                        @extend .font-light;
                        color:$light_color;
                        background: $primary_color;
                    }
                }
                &__description{
                    .title{
                        margin-bottom: 20px;
                        padding-bottom:30px;
                        
                        color:$text_title_color;
                        font-size:16px;
                        letter-spacing: 100/1000 * 16px;
                        font-weight:bold;
                        &.city{
                            background:url(../images/picto-city.svg) no-repeat bottom center;
                            background-size: 50px;
                            @include specific-ie{
                                background-size: 110px;
                            }
                        }
                        &.sea_ocean{
                            background:url(../images/picto-sea.svg) no-repeat bottom center;
                            background-size: 50px;
                            @include specific-ie{
                                background-size: 110px;
                            }
                        }
                        &.greenway{
                            background:url(../images/picto-mountain.svg) no-repeat bottom center;
                            background-size: 50px;
                            @include specific-ie{
                                background-size: 110px;
                            }
                        }
                        &.nature{
                            background:url(../images/picto-nature.svg) no-repeat bottom center;
                            background-size: 40px;
                            @include specific-ie{
                                background-size: 110px;
                            }
                        }
                        &.river{
                            background:url(../images/picto-river.svg) no-repeat bottom center;
                            background-size: 50px;
                            @include specific-ie{
                                background-size: 110px;
                            }
                        }
                        &.castle{
                            background:url(../images/picto-castle.svg) no-repeat bottom center;
                            background-size: 20px;
                            @include specific-ie{
                                background-size: 110px;
                            }
                        }
                    }
                    .description{
                        margin-bottom: 20px;

                        color:$text_base_color;
                        @extend .font-light;
                    }
                    .price{
                        &__from{
                            display:block;
                            font-size:12px;
                            @extend .font-light;
                        }
                        &__number{
                            color:$primary_color;
                            font-weight:bold;
                            font-size:18px;
                        }
                    }
                }
                @include btn-link(5px, 100%, $text_base_color);
            }
        }
        
        //======================TESTIMONY======================/
        .contain-testimony{
            padding:30px 15px 70px 15px;
            
/*                background-size:cover;
                background-repeat: no-repeat;*/
            picture{
                display:block;
                overflow:hidden;
                /*width:100%;*/
            }
            .subcontain-testimony{
                max-width:1000px;
            }
            &__container{
                position:relative;
                width:100%;
                margin-bottom:150px;
                .contain-deco{
                    position:relative;
                }
                .testimonies{
                    position:relative;
/*                        width:580px;
                    min-height:360px;*/
                    box-shadow:10px 10px 0px 0px rgba($light_color, 0.5);
                    background: $light_color;
                    &:before{
                        content:"";
                        position:absolute;
                        top:-10px;
                        left:50%;
                        transform:translateX(-50%);
                        width:60px;
                        height:30px;
                        z-index:500;
                        background: $primary_color url(../images/quote.svg) center center no-repeat;
                        background-size:30px;
                    }
                    .owl-dots {
                        position:absolute;
                        bottom:-100px;
                        left:50%;
                        transform:translateX(-50%);
                        width:60%;
                        counter-reset: dots;
                        span{
                            display:none;
                        }
                        .owl-dot{
                            padding:5px 20px !important;
                            color:$light_color;
                            border: 1px solid $primary_color;
                            transition: all 0.3s;
                            &:before {
                                counter-increment:dots;
                                content: counter(dots);
                            }
                            &.active, &:hover, &:focus{
                                background: $primary_color;
                            }
                            &:nth-child(2){
                                border-left: none;
                                border-right: none;
                            }
                        }
                    }
                    &__testimony{
                        min-height:400px;
                        picture{
                            width:100%;
                            img{
                                width:100%;
                                transition: transform 0.3s;
                                &:hover{
                                    transform: scale(1.1);
                                }
                            }
                        }
                        .block-quote{
                            padding:30px 30px 25px 20px;
                            position:relative;
                            background: $light_color;
                            &__quote{
                                margin-bottom: 15px;
                                text-align:left;
                                font-style: italic;
                                @extend .font-light;
                            }
                            &__namecity{
                                color:$primary_color;
                                font-weight:bold;
                                text-align:right;
                            }
                            &__qualification{
                                text-align:right;
                                @extend .font-light;
                            }
                        }

                        &.no-picture {
                            display: flex;
                            align-items: center;
                        }
                    }
                }
                .afterquote{
                    position:absolute;
                    z-index:20;
                    bottom:-40px;
                    right:100px;
                    width: 0;
                    height: 0;
                    transform: skewX(-36deg);
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 40px solid $light_color;
                }
                .afterquoteshadow{
                    position:absolute;
                    z-index:18;
                    bottom:-50px;
                    right:90px;
                    width: 0;
                    height: 0;
                    transform: skewX(-36deg);
                    border-left: 20px solid transparent;
                    border-right: 20px solid transparent;
                    border-top: 40px solid $light_color;
                    opacity:0.5;
                }
            }
            &__customtravel{
                position:relative;
                padding:25px 25px 120px 25px;
                max-width: 500px;
                margin: 0 auto;
                width:100%;
                box-shadow:10px 10px 0px 0px rgba($light_color, 0.5);
                background: $light_color;
                picture{
                    margin-bottom: 25px;
                    text-align:center;
                    img{
                        width:170px;
                        height:65px;
                    }
                }
                &__contain-text{
                    text-align:center;
                }
                &__catchphrase{
                    @extend .font-light;
                }
                @include btn-link(45px, calc(100% - 50px), $text_base_color);
            }
        }
        
        //======================BLOG & OFFER PART======================/
        .contain-blog-offer{
            padding:70px 15px 70px 15px;

            background-repeat: no-repeat;
            background-size: cover;
            .subcontain-blog-offer{
                max-width:1000px;
            }
            picture{
                display:block;
                overflow:hidden;
/*                img{
                    width:100%;
                }*/
            }

            //***********EACH ITEM***********/
            .itemoffer{
                max-width: 300px;
                margin: 0 auto 30px auto;
            }
            .offerteam{
                min-height:430px;
                background-color: rgba($dark_color,0.8);
                box-shadow:10px 10px 0px 0px rgba($dark_color, 0.16);
            }
            &__offer{
                position:relative;
                min-height: 500px;
                padding: 35px 25px 110px 25px;

                color:$light_color;
                text-align:center;
                background-repeat: no-repeat;
                background-size: cover;
                picture{
                    margin-bottom: 60px;
                    text-align:center;
                    img{
                        width:90px;
                        height:90px;
                    }
                }
                &__title{
                    margin-bottom: 50px;
                }
                &__catchphrase{
                    @extend .font-light;
                }
                @include btn-link(45px, calc(100% - 50px), $light_color);
            }
            &__blog{
                position:relative;
                &__title{
                    margin-bottom: 30px;
                    text-align:center;
                    font-size:30px;
                }
                &__articles{
                    padding-bottom: 70px;
                }
                a{
                    text-decoration:none;
                    color:$text_base_color;
                }
                .blog-item{
                    display:inline-block;
                    position:relative;
                    padding-bottom:30px;
                    margin-bottom: 25px;
                    border-bottom: 1px solid $primary_color;
                    &__title{
                        font-weight:bold;
                        font-size:$font_base;
                    }
                    &__content{
                        @extend .font-light;
                    }
                    &:last-child{
                        border-bottom:none;
                    }
                    .plusarticle{
                        position:absolute;
                        bottom:10px;
                        right:5px;
                        width:20px;
                        height:20px;
                        background:url(../images/plus_article.svg) no-repeat;
                        background-size:20px;
                        transition:all 0.3s;
                    }
                    &:hover,&:focus{
                        .plusarticle{
                            transform: rotate(180deg);
                        }
                    }
                }
                @include btn-link(0px, 100%, $text_base_color);
            }
            &__team{
                position:relative;
                min-height: 500px;
                padding: 35px 25px 110px 25px;

                text-align:center;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: bottom center;
                &__title{
                    margin-bottom: 50px; 
                }
                &__catchphrase{
                    @extend .font-light;
                }
                @include btn-link(45px, calc(100% - 50px), $light_color);
            }
        }
        
        @media (min-width: 480px) {
            //======================HIGHLIGHTED TRIP======================/
            .contain-hightlight-trip{
                padding:30px 90px 30px 90px;
            }
            
            //======================TESTIMONY======================/
            .contain-testimony{
                padding:50px 80px;
            }
            
            //======================BLOG & OFFER PART======================/
            .contain-blog-offer{
                padding:50px 90px 100px 90px;
            }
        }
        
        @media (min-width: 600px){
            //======================HIGHLIGHTED TRIP======================/
            .contain-hightlight-trip{
                
                &__container{
                    display:flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }
                
                padding:30px 40px;
                &__trip{
                    width:calc(100% / 2);
                    padding:0 10px 75px 10px;
                    &__link{
                        width:calc(100% - 20px);
                    }
                }
            }
            
            //======================BLOG & OFFER PART======================/
            .contain-blog-offer{
                padding:50px 30px 200px 30px;

                .subcontain-blog-offer{
                    display: flex;
                    margin:0 auto;
                    flex-wrap: wrap;
                    justify-content: center;
                    .itemoffer{
                        width:calc((100% / 2) - 20px);
                        &:nth-child(2){
                            margin-left:20px;
                        }
                    }
                }
            }
        }
        
        @media (min-width: 768px) {
            //======================HIGHLIGHTED TRIP======================/
            .contain-hightlight-trip{
                /*display:flex;*/
                
                
                &__container{
                    flex-wrap: nowrap;
                }
                
                padding:75px 20px 65px 20px;
                &__trip{
                    margin-bottom: 0px;
                    padding:0 0px 75px 0px;
                    width:calc((100% / 3) - (40px / 3));
                    &__link{
                        width:100%;
                    }
                    &:nth-child(2){
                        margin:0 20px;
                    }
                }
            }
            
            //======================TESTIMONY======================/
            .contain-testimony{
                padding:50px 30px;
                min-height:530px;

                background-size:cover;
                background-repeat: no-repeat;
                picture{
                    display:block;
                    overflow:hidden;
                }
                &__container{
                    width:100%;
                    .contain-deco{
                        width: 580px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    .testimonies{
                        position: relative;
                        width:580px;
                        min-height:360px;
                        &:after{
                            right:40px;
                        }
                        &__testimony{
                            display: flex;
                            width:580px;
                            min-height:360px;
                            picture{
                                width:275px;
                                min-height:360px;
                                img{
                                    width:100%;
                                    height:100%;
                                    object-fit: cover;
                                }
                            }
                            .block-quote{
                                width:calc(100% - 275px);
                                padding:90px 30px 25px 20px;
                                &__contain-name{
                                    position:absolute;
                                    bottom:25px;
                                    right:30px;
                                }
                            }
                            &.no-picture {
                                align-items: unset;
                                .block-quote {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
                &__customtravel{
                    width:100%;
                }
            }
            
            //======================BLOG & OFFER PART======================/
            .contain-blog-offer{
                padding:50px 25px 200px 25px;

                background-repeat: no-repeat;
                background-size: cover;
                .subcontain-blog-offer{
                    flex-wrap: nowrap;
                }

                //***********EACH ITEM***********/
                .itemoffer{
                    margin-bottom: 0px;
                    width:calc((100% / 3) - (40px / 3));
                    min-height:500px;
                    &:nth-child(2){
                        margin:0 20px;
                    }
                }
            }
        }

        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
            
            //======================HEAD PAGE======================/
            .contain-head-page{
                &__text{
                    top:20%;
                    width:45%;
                    min-width:840px;
                    &__titles{
                        padding-bottom:100px;
                        margin-bottom:200px;

                        background:url(../images/logo_velo.svg) bottom center no-repeat;
                        background-size:80px;
                        @include specific-ie{
                            background-size:380px;
                        }
                        .subline1, .subline2{
                            display: block;
                        }
                    }
                    &__travel-choice{
                        padding:25px;
                        max-width:none;
                        ul{
                            display: flex;
                            li{
                                &.travel{
                                    margin-right:20px;
                                    margin-bottom: 0;
                                    line-height:54px;
                                }
                                &.oneitem{
                                    a{
                                        padding:15px 50px;
                                    }
                                    &:nth-child(3) a{
                                        border:1px solid $primary_color;
                                        border-left:none;
                                        border-right:none;
                                    }
                                }
                                &.smallmap{
                                    display:block;
                                    margin-left:35px;
                                    img{
                                        // width:40px;
                                        // height:45px;

                                        width: 50px;
                                        // height: 50px;

                                    }
                                }
                            }
                        }
                    }
                }
                &__contain-images{
                    max-height:750px;
                    &__image{
                        position:relative;
                        max-height:750px;
                        .caption{
                            display:block;
                            position:absolute;
                            right:17%;
                            bottom:25%;
                            color:$light_color;
                        }
                    }
                    picture{
                        display:block;
                        width:100%;
                        img{
                            width:100%;
                            min-width:1380px;
                        }
                    }
                }
            }
            
            //======================BLOG & OFFER PART======================/
            .contain-blog-offer{
                //***********EACH ITEM***********/
                .itemoffer{
                    max-width: none;
                }
            }
        }

        @media (min-width: 1300px) {
            //======================HEAD PAGE======================/
            .contain-head-page{
                &__text{
                    top:20%;
                    width:55%;
                    min-width:860px;
                    max-width:860px;
                    &__travel-choice{
                        ul{
                            li{
                                &.travel{
                                }
                                &.oneitem{
                                    a{
                                    }
                                    &:nth-child(3) a{
                                    }
                                }
                                &.smallmap{
                                    display:block;
                                    margin-left:45px;
                                }
                            }
                        }
                        .arrow-scroll{
                            position:absolute;
                            right: -80px;
                            top:30px;
                            cursor:pointer;
                            width:50px;
                            height:50px;
                            background: url(../images/arrow_down_scroll.svg) no-repeat;
                            transition:all 0.3s;
                            &:hover, &:focus{
                                transform: translateY(2px);
                            }
                        }
                    }
                }
                &__contain-images{
                    &__image{
                    }
                    picture{
                    }
                }
            }


            //======================TESTIMONY======================/
            .contain-testimony{
                padding:50px 90px;
                min-height:530px;

                background-size:cover;
                background-repeat: no-repeat;
                picture{
                    display:block;
                    overflow:hidden;
                }
                .subcontain-testimony{
                    display: flex;
                    margin:0 auto;
                }
                &__container{
                    width:70%;
                    .contain-deco{
                        left:0;
                        transform:none;
                    }
                    .testimonies{
                        width:580px;
                        min-height:360px;
                        left:0%;
                        transform:none;
                        &:after{
                            right:40px;
                        }
                        &__testimony{
                            display: flex;
                            width:580px;
                            min-height:360px;
                            picture{
                                width:275px;
                                min-height:360px;
                                img{
                                    width:100%;
                                    height:100%;
                                    object-fit: cover;
                                }
                            }
                            .block-quote{
                                width:calc(100% - 275px);
                                padding:90px 30px 25px 20px;
                                &__contain-name{
                                    position:absolute;
                                    bottom:25px;
                                    right:30px;
                                }
                            }
                        }
                    }
                }
                &__customtravel{
                    width:30%;
                }
            }

            //======================BLOG & OFFER PART======================/
            .contain-blog-offer{
                padding:90px 90px 380px 90px;

                background-repeat: no-repeat;
                background-size: cover;
                
                //***********EACH ITEM***********/
                .itemoffer{
                    margin-bottom: 0px;
                    width:calc((100% / 3) - (40px / 3));
                    min-height:500px;
                    &:nth-child(2){
                        margin:0 20px;
                    }
                }
            }
        }
        
        @media (min-width: 1500px) {
            //======================HEAD PAGE======================/
            .contain-head-page{
                &__contain-images{
                    &__image{
                        .caption{
                            right:25%;
                        }
                    }
                }
            }
        }
    }
}
