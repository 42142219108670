body.error404 {
	background: $light_color;
    
    .main-content{
        padding:0;
        div{
            padding:0;
        }
        //======================HEAD PAGE======================/
        .contain-head-404page{
            position:relative;
            overflow: hidden;
            &__text{
                position:absolute;
                top:35%;
                left:50%;
                transform:translateX(-50%);
                width:85%;
                z-index:1;
                &__title{
                    margin-bottom: 15px;
                    
                    color:$light_color;
                    line-height: 40px;
                    text-align: center;
                }
            }
            
            &__contain-image{
                max-height:250px;
                picture{
                    display:block;
                    max-height:250px;
                    overflow:hidden;
                    img{
                        width:100%;
                    }
                }
            }
        }
        
        .content-404{
            padding:35px 30px 45px 30px;
            a{
                color:$primary_color;
                transition:all 0.3s;
                &:hover,&:focus{
                    color:$dark_color;
                }
            }
        }
        
        
        @media (min-width: 1100px) {
            width:calc(100% - 250px);
            margin:0;
            margin-left:250px;
        }
    }
}
